import React, { useRef, useState } from "react";
import axios from "axios";
import * as yup from 'yup';
import { Main } from "./styles";
import { PageTitle } from "../../components/PageTitle";
import { Container } from '../../components/Container';
import { baseUrl } from "../../Constant";
import { Input } from "../../components/Input";
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { useHistory } from "react-router-dom";
import { TextArea } from "../../components/TextArea";
import { Inputmask } from "../../components/InputMask";

export const FormContactPage = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const formRef = useRef(null);
    const history = useHistory();

    const onSubmitMessage = async (data) => {
        try {
            const schema = yup.object().shape({
                nomeContato: yup.string().required('Digite seu nome'),
                emailContato: yup.string().required('Digite seu email').email('Digite um email válido'),
                telefoneContato: yup.string().required('Digite seu telefone').matches(/^\([0-9]{2}\) [0-9]?[0-9]{4}-[0-9]{4}$/, 'Digite um número de telefone válido'),
                descricaoContato: yup.string().required('Digite sua mensagem')
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            formRef.current.setErrors({});

            const body = {
                nomeContato: data.nomeContato,
                emailContato: data.emailContato,
                telefoneContato: data.telefoneContato,
                descricaoContato: data.descricaoContato,
            }

            await axios.post(`${baseUrl}/messages`, body)
            history.push("/contato/sucess")

        } catch (err) {

            const validationErrors = {};

            if (err instanceof yup.ValidationError) {
                err.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            } else if (err.response.status >= 400) {
                setErrorMessage(err.response.data.mensagem)
            }
        }
    };
    return (
        <Container>
            <Header />
            <Main ref={formRef} onSubmit={onSubmitMessage} className="main">
                <PageTitle text={"Formulário de Contato"} />
                {<p style={{ color: '#F00', textAlign: 'center', textTransform: 'Uppercase' }}>{errorMessage}</p>}
                <Input
                    label="Nome Completo"
                    name="nomeContato" />
                <Input
                    label="E-mail"
                    name="emailContato"
                    type="email" />
                <Inputmask
                    label="Telefone"
                    name="telefoneContato"
                    type="tel"
                    mask="(99) 99999-9999"/>
                <TextArea
                    label="Descrição"
                    component={"textarea"}
                    rows="6"
                    name="descricaoContato"
                    type="text"
                    placeholder="Descreva aqui o perfil de imovél que você está a procura."
                />
                <button type="submit" className="ripple">ENVIAR</button>
            </Main>
            <Footer />
        </Container>
    );
};
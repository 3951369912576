import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import * as yup from 'yup';
import { FormLogin } from "./styles";
import { PageTitle } from "../../components/PageTitle";
import { Container } from '../../components/Container';
import { baseUrl } from "../../Constant";
import { Input } from "../../components/Input";
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { useHistory } from "react-router-dom";

export const LoginPage = () => {
    const [ errorMessage, setErrorMessage] = useState('');
    const formRef = useRef(null);
    const history = useHistory();

    const token = localStorage.getItem('authorization');
    
    useEffect(()=>{
        if(token){
            history.push("/lista-de-imoveis");
        }
    }, [history, token, errorMessage])

    const onSubmitLogin = async (data) => {
        try {
            const schema = yup.object().shape({
                name: yup.string().required('Digite seu nome'),
                password: yup.string().required('Digite sua senha')
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            formRef.current.setErrors({});

            const body = {
                username: data.name,
                password: data.password
            }

            const response = await axios.post(`${baseUrl}/auth`, body)
            localStorage.setItem("authorization", response.data.auth);
            history.push("/lista-de-imoveis")

        } catch (err) {
            
            const validationErrors = {};
            console.log(err);
            if (err instanceof yup.ValidationError) {
                err.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            } else if(err.response.status){
                setErrorMessage(err.response.data.message)
            }
        }
    };
    return (
        <Container>
            <Header />
            <FormLogin ref={formRef} onSubmit={onSubmitLogin}>
                <PageTitle text={"Área de Login"} />
                <p style={{ color: '#F00', textAlign: 'center', padding: '1%'}}>{errorMessage}</p>
                <Input
                    label="Usuário"
                    name="name"/>
                <Input
                    label="Senha"
                    name="password"
                    type="password" />
                <button type="submit">ENTRAR</button>
            </FormLogin>
            <Footer />
        </Container>
    );
};
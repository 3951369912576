import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { whatsNumber } from "../../Constant";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#216632",
    color: "#fff",
    position: "fixed",
    bottom: "6%",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "green",
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

export const WhatsAppButton = () => {
  const classes = useStyles();
  return (
    <Fab
      variant="extended"
      size="medium"
      aria-label="atendimento"
      className={classes.root}
      href={whatsNumber}
      target="blank"
      id="buttonWhatsApp"
    >
      <WhatsAppIcon className={classes.margin} />
      <span className="textWhatsApp">Atendimento WhatsApp</span>
    </Fab>
  );
};

import React, { useRef, useEffect } from "react";
import ReactSelect from "react-select";
import { useField } from "@unform/core";

import styled from "styled-components";

const CustomSelect = styled(ReactSelect)`
  width: 190px;
  max-width: 80%;
  font-size: 12px;
  margin-left: 8px;
  .react-select-container {
    margin: 0 9px;
    font-size: 12px;
  }
  .react-select__control {
    border: 1px solid #4ead8f;
    background: transparent;
    border-radius: 10px;
    height: 40px;
    max-height: 40px;
    box-shadow: none;
  }
  .react-select__control--is-focused{
    background-color: white;
    :hover{border: 1px solid #4ead8f;}
  }
  .react-select__value-container {
    height: 99%;
    padding: 0;
    margin: 0;
    div{
      padding: 0 10px;
      margin: 0;
    }
    .react-select__placeholder {
      margin: 0;
      padding: 10px;
    }
    .react-select__input{
      padding: 0;
    }
  }
  @media(max-width: 450px){
    width: auto;
    max-width: 100%;
    font-size: 12px;
    padding: 0 8px;
    margin: 0;
  }
`;

export const SelectFiltro = ({ name, placeholder, options, isClear, ...rest }) => {
  const selectRef = useRef(null);
  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option) => option.value);
        }
        if (!ref.state.value) {
          return "";
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <CustomSelect
      placeholder={placeholder}
      options={options}
      isClearable
      ref={selectRef}
      classNamePrefix={"react-select"}
      isSearchable={false}
      noOptionsMessage={() => "Sem opções"}
      {...rest}
    />
  );
};

import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { HomePage } from "../pages/HomePage";
import { LoginPage } from "../pages/LoginPage";
import { RegisterPropertyPage } from "../pages/RegisterPropertyPage";
import { ListPropertyPage } from "../pages/ListPropertyPage";
import { EditPropertyPage } from "../pages/EditPropertyPage";
import { ListContactsPage } from "../pages/ListContactsPage";
import { EditImage } from "../pages/EditImage";
import { ErrorPage } from "../pages/ErrorPage";
import { FormContactPage } from "../pages/FormContact";
import { PageSucess } from "../pages/FormContact/PageSucess";
import { DetailsPropertyPage } from "../pages/DetailsPropertyPage";

export const Router = () => {
  return (
    <BrowserRouter key={Router}>
          <Switch>
            <Route path="/contato/sucess" exact>
              <PageSucess />
            </Route>
            <Route path="/contato" exact>
              <FormContactPage />
            </Route>
            <Route path="/auth" exact>
              <LoginPage />
            </Route>
            <Route path="/cadastrar-imovel" exact>
              <RegisterPropertyPage />
            </Route>
            <Route path="/detalhes-imovel/:id" exact>
              <DetailsPropertyPage />
            </Route>
            <Route path="/editar-imovel/:id" exact>
              <EditPropertyPage />
            </Route>
            <Route path="/editar-imagens/:id" exact>
              <EditImage />
            </Route>
            <Route path="/lista-de-contatos" exact>
              <ListContactsPage />
            </Route>
            <Route path="/lista-de-imoveis" exact>
              <ListPropertyPage />
            </Route>
            <Route path="/" exact>
              <HomePage />
            </Route>
            <Route path="/">
              <ErrorPage />
            </Route>
          </Switch>
    </BrowserRouter>
  );
};

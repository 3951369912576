import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";

export const TextArea = (props) => {
  const textRef = useRef(null);
  const {
    fieldName,
    registerField,
    defaultValue = props.value,
    error,
  } = useField(props.name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);
  return (
    <div className={props.className}>
      {props.label && <label htmlFor={fieldName}>{props.label}</label>}

      <textarea
        ref={textRef}
        defaultValue={defaultValue}
        {...props.rest}
        placeholder={props.placeholder}
        disabled={props.disabled}
      />

      {error && <p style={{ color: "#F00"}}>{error}</p>}
    </div>
  );
};

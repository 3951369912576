export const tipoDoImovel = [
    { value: '', label: 'Todos'},
    { value: 'Casa', label: 'Casa'},
    { value: 'Apartamento', label: 'Apartamento'},
    { value: 'Terreno', label: 'Terreno'},
    { value: 'Kitnet', label: 'Kitnet'},
    { value: 'Galpao', label: 'Galpão'}
];
export const finalidade = [
    { value: '', label: 'Todos'},
    { value: 'Alugar', label: 'Alugar'},
    { value: 'Vender', label: 'Vender'},
    { value: 'Permuta', label: 'Permuta'},
    { value: 'Alugar/Vender', label: 'Alugar/Vender'},
];
export const estado = [
    { value: '', label: 'Todos'},
    { value: 'Novo', label: 'Novo'},
    { value: 'Usado', label: 'Usado'}
];
export const quartos = [
    { value: '', label: 'Todos'},
    { value: '00', label: '00'},
    { value: '01', label: '01'},
    { value: '02', label: '02'},
    { value: '03', label: '03'},
    { value: '04', label: '04'},
    { value: '05', label: '05'},
    { value: '06', label: '06'},
    { value: '07', label: '07'},
    { value: '08', label: '08'},
    { value: '09', label: '09'},
    { value: '10', label: '10'},
];
import React from "react";
import { Content } from "./styles";
import { Upload } from "./Upload";
import { FileList } from "./FileList";
import imageCompression from "browser-image-compression";

export const UploadImages = (props) => {

  const handleUpload = async (files) => {

    const filescompression = [];
    
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    };

    try {
      for (let i = 0; i < files.length; i++) {
        const compressedFile = await imageCompression(files[i], options)
        
        filescompression.push(compressedFile);
      }
      await props.setUploadedFiles([...props.uploadedFiles, ...filescompression]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = (name) => {
    props.setUploadedFiles(
      props.uploadedFiles.filter((file) => file.name !== name)
    );
  };

  return (
    <Content className={props.className}>
      {props.label && <label>{props.label}</label>}
      <Upload onUpload={handleUpload} setUploadedFiles={props.setUploadedFiles} uploadedFiles={props.uploadedFiles}/>
      {!!props.uploadedFiles.length && (
        <FileList files={props.uploadedFiles} onDelete={handleDelete} />
      )}
    </Content>
  );
};

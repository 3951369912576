import React, { useEffect, useState } from "react";
import { ShowProperty, Favorite, ImageDefault } from "./styled";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { Carousel } from "./Carrosel";
import FavoriteColor from "@material-ui/icons/FavoriteBorder";
import casa from "../../images/casa.png";
import { formatedValue } from "../../hooks/FormatedValue";

export const CardShowProperty = ({
  house,
  favorites,
  setFavorites,
  displayNone,
}) => {
  const [favoriteCard, setFavoriteCard] = useState();

  useEffect(() => {
    const activeFavorite = () => {
      let favoriteId = favorites.find((favorite) => house.id === favorite);
      favoriteId === house.id ? setFavoriteCard(true) : setFavoriteCard(false);
    };

    activeFavorite();
  }, [house, favorites, favoriteCard]);

  const favoriteOn = (id) => {
    const addFavorites = [...favorites, id];
    localStorage.setItem("favoritesTerraViva", JSON.stringify(addFavorites));

    setFavorites(addFavorites);

    setFavoriteCard(true);
  };

  const favoriteOff = (id) => {
    const favoritesFilter = favorites.filter((favorite) => {
      return favorite !== id;
    });
    setFavorites(favoritesFilter);

    localStorage.removeItem("favoritesTerraViva");
    localStorage.setItem("favoritesTerraViva", JSON.stringify(favoritesFilter));

    setFavoriteCard(false);
  };

  return (
    <ShowProperty displayNone={displayNone}>
      {house.files.length === 0 ? (
        <ImageDefault src={casa} status={house.status}>
          {house.status ? (
            ""
          ) : (
            <p>{house.disponibilidade === "Alugar" ? "+1 ALUGADO" : "+1 VENDIDO"}</p>
          )}
        </ImageDefault>
      ) : (
        <Carousel files={house.files} house={house} />
      )}
      <div className="details">
        <div>
          <p className="titulo">{house.titulo}</p>
          <Favorite>
            {favoriteCard === false ? (
              <FavoriteColor onClick={() => favoriteOn(house.id)} />
            ) : (
              <FavoriteIcon onClick={() => favoriteOff(house.id)} />
            )}
          </Favorite>
        </div>
        <div>
          <span>
            {house.tipo} | {house.disponibilidade}
          </span>
          <p className="valor">{formatedValue(house.valor)}</p>
        </div>
        <div>
          <span>{house.uf}</span>
          <span>{house.cidade}</span>
        </div>
        <div>
          <span>{house.endereco}</span>
          <span>{house.bairro}</span>
        </div>
        <div className="descricao">
          <p>{house.descricao}</p>
        </div>
      </div>
    </ShowProperty>
  );
};

import { Form } from '@unform/web';
import styled from 'styled-components';

export const FormLogin = styled(Form)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    position: relative;
    height: 100%;
    width: 100%;
    div{
        display: flex;
        flex-direction: column;
        align-items: center; 
        width: 100%;
    }
    label{
        text-align: start;
        width: 30%;
        margin-top: 22px;
        font-size: 15px;
        font-weight: bolder;
        color: #216632;
        text-shadow: 0px 1px 3px #00000029;
    }
    input{
        height: 46px;
        width: 30%;    
        font-size: 15px;
        color: #1A1A1D;
        padding: 12px;
        border: none;
        margin-top: 6px;
        border-radius: 10px;
        box-shadow: 0px 3px 6px #00000029;
        &:focus{
            outline: 0;
            /* COLOR ERROR */
            border: 1px solid #DC3545;
             /* Color CORRECT */
            border: 1px solid #28A745; 
        }
    };
    button{
        height: 46px;
        width: 30%;
        border: none;
        border-radius: 10px;
        margin-top: 22px;
        background-color: #28A745;
        color: #FFFFFF;
        box-shadow: 0px 3px 6px #00000029;
        :focus{
        outline: thin dotted;
        outline: 0px auto -webkit-focus-ring-color;
        outline-offset: 0px;
      }
    }
    p{
        font-size: 12px;
        width: 30%;
        text-align: left;
    }
    @media (min-width: 280px) and (max-width: 480px) {
        input, label, button{width: 100%;}
    }
    @media (min-width: 540px) and (max-width: 720px) {
        input, label, button{width: 100%;}
    }
`;

import React, { useEffect, useRef, useState } from "react";
import { Container } from "../../components/Container";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { PaginationProgress } from "../../components/PaginationProgress";
import axios from "axios";
import { baseUrl, inicialValue } from "../../Constant";
import { Section, Main, ListProperty } from "./styled";
import { Cards } from "./Cards";
import { CardShowProperty } from "./CardShowProperty";
import { WhatsAppButton } from "./WhatsAppButton";
import { ButtonCookies } from "./ButtonCookies";
import { ButtonContact } from "./ButtonContact";
import { Filters } from "./Filter/Filters";
import { NoProperty } from "../../components/NoProperty";
import { BackDrop } from "../../components/BackDrop";
import { CircularProgress } from "@material-ui/core";

export const HomePage = () => {
  const formRef = useRef(null);
  const [listProperty, setListProperty] = useState([]);
  const [house, setHouse] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [page, setPage] = useState(1);
  const [favorites, setFavorites] = useState(inicialValue || []);
  const [textValue, setTextValue] = useState("");
  const [openFilter, setOpenFilter] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [searchProperty, setSearchProperty] = useState([]);

  useEffect(() => {
    getProperty();
  }, [page]);

  //Busca da Lista de Imóveis cadastrados
  const getProperty = async () => {
    setOpenLoading("Carregando ...");

    const response = await axios.get(`${baseUrl}/properties`, {
      headers: { favorites: `"${favorites}"` },
      params: { page, perPage: 20 },
    });

    setNumberOfPages(response.data.numberOfPages);
    setListProperty(response.data.properties);
    setOpenLoading(false);
    if (response.data.properties.length) {
      setHouse(response.data.properties[0]);
    }
    if (response.data.properties.length === 0) {
      setTextValue("Nenhum Imóvel foi encontrado");
    }
  };

  // Mostrar Imóvel selecionado
  const showHouse = (house) => {
    setHouse(house);
  };

  //Buscar Imóvel pelos campos select de pesquisa
  const search = async (data) => {
    try {
      setOpenLoading("Carregando ...");

      data.valorMaximo = data.valorMaximo
        .replace("R$", "")
        .replace(/\./g, "")
        .replace(/,/g, ".");

      const response = await axios.get(`${baseUrl}/properties`, {
        headers: { favorites: `"${favorites}"` },
        params: {
          page,
          perPage: 20,
          type: data.tipo,
          objective: data.finalidade,
          city: data.cidade,
          hood: data.bairro,
          bedrooms: data.quartos,
          value: data.valorMaximo,
        },
      });

      setNumberOfPages(response.data.numberOfPages);
      setListProperty(response.data.properties);
      if (response.data.properties.length === 0) {
        setTextValue("Nenhum Imóvel foi encontrado");
      }
      setOpenFilter(false);
      setOpenLoading(false);
    } catch (err) {
      console.log("Deu erro", err);
    }
  };
  
  //Buscar Imóvel pela barra de pesquisa
  const searchInput = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${baseUrl}/search`, {
        headers: { favorites: `"${favorites}"` },
        params: {
          search: searchProperty,
          page,
          perPage: 20
        },
      });
      
      setNumberOfPages(response.data.numberOfPages);
      setListProperty(response.data.properties);
      if (response.data.properties.length === 0) {
        setTextValue("Nenhum Imóvel foi encontrado");
      }
      setOpenFilter(false);
      setOpenLoading(false);
    } catch (err) {
      console.log("Deu erro", err);
    }
  }

  return (
    <Container>
      <Header button={<ButtonContact />} />
      <Main className="main">
        <Filters
          search={search}
          searchProperty={searchProperty}
          setSearchProperty={setSearchProperty}
          onClickSearch={searchInput}
          formRef={formRef}
          open={openFilter}
          setOpen={setOpenFilter}
        />
        <Section>
          <ListProperty>
            {listProperty.length === 0 ? (
              <NoProperty
                position={"absolute"}
                margin={"10%"}
                text={textValue}
              />
            ) : (
              listProperty.map((property) => {
                return (
                  <Cards
                    key={property.id}
                    imovel={property}
                    onCLickShowHouse={() => showHouse(property)}
                    favorites={favorites}
                    setFavorites={setFavorites}
                  />
                );
              })
            )}
          </ListProperty>
          {house.length === 0 ? (
            ""
          ) : (
            <CardShowProperty
              displayNone={listProperty.length}
              house={house}
              favorites={favorites}
              setFavorites={setFavorites}
            />
          )}
        </Section>
        <PaginationProgress
          setPage={setPage}
          page={page}
          numberOfPages={numberOfPages}
        />
      </Main>
      <WhatsAppButton />
      <ButtonCookies />
      <Footer />
      {openLoading === "Carregando ..." ? (
        <BackDrop open={openLoading} text={<CircularProgress color="inherit" />} />
      ) : (
        <BackDrop open={openLoading} />
      )}
    </Container>
  );
};

import React from 'react';
import styled from 'styled-components';

const Title = styled.h1`
    font-size: 24px;
    color: #216632;
    text-align: center;
`;

export const PageTitle = (props) => {
return <Title>{props.gif}{props.text}</Title>
}
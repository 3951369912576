import * as yup from 'yup';

export const schema = yup.object().shape({
    titulo: yup.string().required('Esse campo é obrigatório'),
    tipo: yup.string().required('Esse campo é obrigatório'),
    disponibilidade: yup.string().required('Esse campo é obrigatório'),
    quartos: yup.number().required(),
    estado: yup.string().required('Esse campo é obrigatório'),
    area: yup.string().required('Esse campo é obrigatório'),
    valor: yup.number().required(),
    proprietario: yup.string().required('Esse campo é obrigatório'),
    contato: yup.string().required('Esse campo é obrigatório'),
    cep: yup.string().required('Esse campo é obrigatório'),
    cidade: yup.string().required('Esse campo é obrigatório'),
    endereco: yup.string(),
    bairro: yup.string(),
    uf: yup.string().required('Esse campo é obrigatório'),
    descricao: yup.string().required('Esse campo é obrigatório')
});
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ModalPrivacyPolicy } from "./ModalPrivacyPolicy";

const Container = styled.div`
  position: fixed;
  bottom: 6%;
  left: 2%;
  padding: 10px;
  display: ${(props) => (props.isAccept === true ? "flex" : "none")};
  align-items: center;
  justify-content: space-evenly;
  font-size: 12px;
  border-radius: 10px;
  background-color: green;
  color: #ffffff;
  width: 95%;
  button {
    border: none;
    height: 40px;
    border: 1px solid green;
    border-radius: 10px;
    cursor: pointer;
    width: 100px;
    :hover {
      color: green;
    }
  }
  a {
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
    :hover {
      color: blue;
    }
  }
  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

export const ButtonCookies = () => {
  const [open, setOpen] = useState(false);
  const [isAccept, setIsAccept] = useState(true);
  const allFavorites = localStorage.getItem("favoritesTerraViva");

  useEffect(() => {
    if (allFavorites) {
      setIsAccept(false);
    }
  }, [isAccept, allFavorites]);

  const acceptCookie = () => {
    localStorage.setItem("favoritesTerraViva", JSON.stringify([]));
    setIsAccept(false);
  };
  return (
    <Container aria-label="atendimento" isAccept={isAccept}>
      <p>
        Este site usa cookies para garantir uma melhor experiência. Ao continuar
        a navegar, você está de acordo com isso. Para saber mais, acesse:{" "}
        <a as="button" onClick={() => setOpen(true)} target="blank">
          Política de Privacidade
        </a>
        .
      </p>
      <button onClick={acceptCookie}>Entendi</button>
      <ModalPrivacyPolicy open={open} setOpen={setOpen} />
    </Container>
  );
};

import React, { useEffect, useState } from "react";
import { finalidade, quartos, tipoDoImovel } from "./data";
import { BodyFilter, FiltersContainer, SearchContainer } from "../styled";
import SearchIcon from "@material-ui/icons/Search";
import { SelectFiltro } from "./SelectFiltro";
import axios from "axios";
import { InputValue } from "../../../components/InputValue";
import CloseIcon from "@material-ui/icons/Close";
import { baseUrl } from "../../../Constant";
import { SearchBar } from "../../../components/SearchBar";

export const Filters = ({
  search,
  formRef,
  open,
  setOpen,
  searchProperty,
  setSearchProperty,
  onClickSearch,
}) => {
  const [cidades, setCidades] = useState([]);
  const [bairros, setBairros] = useState([]);

  useEffect(() => {
    //Busca Cidades e Bairros cadastrados no banco de dados
    const getHoods = async () => {
      const { data } = await axios.get(`${baseUrl}/hoods`);

      const hoods = data.messages.map((hood) => ({
        label: hood.name,
        value: hood.name,
      }));
      setBairros(hoods);
    };
    const getCity = async () => {
      const { data } = await axios.get(`${baseUrl}/cities`);

      const city = data.messages.map((city) => ({
        label: city.name,
        value: city.name,
      }));
      setCidades(city);
    };

    getCity();
    getHoods();
  }, []);

  return (
    <FiltersContainer>
      <button className="buttonFilter" onClick={() => setOpen(!open)}>
        Filtrar
      </button>
      <BodyFilter open={open} ref={formRef} onSubmit={search}>
        <button className="buttonClose" onClick={() => setOpen(false)}>
          <CloseIcon />
        </button>
        <SelectFiltro
          name="tipo"
          placeholder="Tipo do Imóvel"
          options={tipoDoImovel}
        />
        <SelectFiltro
          placeholder="Finalidade"
          name="finalidade"
          options={finalidade}
        />
        <SelectFiltro placeholder="Cidade" name="cidade" options={cidades} />
        <SelectFiltro placeholder="Bairro" name="bairro" options={bairros} />
        <SelectFiltro placeholder="Quartos" name="quartos" options={quartos} />
        <InputValue
          placeholder="Valor Máximo"
          name="valorMaximo"
          className="inputValue"
        />
        <button
          className={"buttonClearFilter"}
          onClick={() => window.location.reload(false)}
        >
          Limpar Filtro
        </button>
        <button type="submit">
          <SearchIcon size="big" />
        </button>
      </BodyFilter>
      <SearchContainer>
        <SearchBar
          text="Pesquisar"
          width="auto"
          searchValue={searchProperty}
          setSearchValue={setSearchProperty}
          search={onClickSearch}
        />
      </SearchContainer>
    </FiltersContainer>
  );
};

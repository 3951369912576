import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useHistory } from "react-router-dom";
import { ModalDelete } from "../../components/ModalDelete";
import axios from "axios";
import { baseUrl } from "../../Constant";
import Modal from "react-modal";

Modal.setAppElement("#root");
const ITEM_HEIGHT = 48;

export const LongMenu = ({ id, listProperty, setListProperty }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openMenu = Boolean(anchorEl);
  const history = useHistory();

  const token = localStorage.getItem('authorization');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const seeProperty = () => {
    setAnchorEl(null);
    history.push(`/detalhes-imovel/${id}`);
  };
  const editImage = () => {
    setAnchorEl(null);
    history.push(`/editar-imagens/${id}`);
  };
  const editProperty = () => {
    setAnchorEl(null);
    history.push(`/editar-imovel/${id}`);
  };
  const confirmeDel = () => {
    setAnchorEl(null);
    setModalIsOpen(true);
  };
  const deleteProperty = async () => {
    
    setModalIsOpen(false);
    
    await axios.delete(`${baseUrl}/properties/${id}`, {
      headers: {
        auth: `${token}`,
      },
    });

    setListProperty(
      listProperty.filter((property) => {
        return property.id !== id;
      })
    );
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={openMenu}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "15ch",
            padding: 1,
          },
        }}
      >
        <MenuItem onClick={editProperty}>Editar Imovél</MenuItem>
        <MenuItem onClick={editImage}>Editar Imagens</MenuItem>
        <MenuItem onClick={confirmeDel}>Deletar Imovél</MenuItem>
        <MenuItem onClick={seeProperty}>Detalhes do Imovél</MenuItem>
      </Menu>
      <ModalDelete
        setIsOpen={setModalIsOpen}
        modalIsOpen={modalIsOpen}
        deleteItem={deleteProperty}
        text={"Tem certeza que deseja deletar esse Imovel?"}
      />
    </div>
  );
};

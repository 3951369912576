import React from "react";

import { FileListContainer, FileInfo } from "./styles";

export const FileList = ({ files, onDelete }) => {
    return (
        <FileListContainer>
            {files.map(uploadedFile => (
                <li key={uploadedFile.name}>
                    <FileInfo>
                        <span>
                            {uploadedFile.name}
                        </span>
                        <button onClick={() => onDelete(uploadedFile.name)}>
                            X
                        </button>
                    </FileInfo>
                </li>
            ))}
        </FileListContainer>
    )
};

import React from 'react';
import Pagination from '@material-ui/lab/Pagination';

export const PaginationProgress = (props) => {
  
  const handleChange = (event, value) => {
    props.setPage(value);
  };

  return (
    <div className="pagination" style={{display: props.numberOfPages <= 1 ? 'none': 'flex'}}>
      <Pagination count={props.numberOfPages} page={props.page} onChange={handleChange}/>
    </div>
  );
}
import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../Constant";

import { Container } from "../../components/Container";
import { PageTitle } from "../../components/PageTitle";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { Main, Section } from "./styled";
import { SearchBar } from "../../components/SearchBar";
import { Cards } from "./Cards";
import { Buttons } from "./Buttons";
import { useProtectedPage } from "../../hooks/useProtectedPage";
import { ModalContact } from "./ModalContact";
import { NoProperty } from "../../components/NoProperty";
import Modal from "react-modal";

Modal.setAppElement("#root");
export const ListContactsPage = () => {
  const [listMessage, setListMessage] = useState([]);
  const [message, setMessage] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [textValue, setTextValue] = useState("Carregando...");

  const token = localStorage.getItem('authorization');

  useProtectedPage();
  useEffect(() => {
    const getMessage = async () => {
      const response = await axios.get(`${baseUrl}/messages`, {
        headers: { auth: token }
      });


      setListMessage(response.data.messages);

      if (response.data.messages.length === 0) {
        setTextValue("Nenhum contato foi encontrado");
      }
    };
    getMessage();
  }, []);

  const showContact = (contato) => {
    setMessage(contato);
    setIsOpen(true);
  };

  const search = async (e) => {
    e.preventDefault();
    try {

      const response = await axios.get(`${baseUrl}/contacts`, {
        params: {
          search: searchValue
        },
        headers: {
          auth: token
        },
      });

      setListMessage(response.data.contacts);

      if (response.data.contacts.length === 0) {
        setTextValue(`Nenhum contato foi encontrado como "${searchValue}"`);
      }
    } catch (err) {
      console.log("Deu erro", err);
    }
  };

  return (
    <Container>
      <Header button={<Buttons />} />
      <Main className="main">
        <div className="nav">
          <PageTitle text="Lista de Contatos" />
          <SearchBar
            text="Pesquisar por nome, email ou telefone"
            search={search}
            setSearchValue={setSearchValue}
          />
        </div>
        <Section>
          {listMessage.length === 0 ? (
            <NoProperty text={textValue} margin={"15%"} />
          ) : (
            listMessage.map((contato) => {
              return (
                <Cards
                  key={contato.id}
                  contato={contato}
                  showContact={showContact}
                />
              );
            })
          )}
        </Section>
        <ModalContact
          setIsOpen={setIsOpen}
          modalIsOpen={modalIsOpen}
          contato={message}
        />
      </Main>
      <Footer />
    </Container>
  );
};

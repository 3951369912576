import React from "react";
import styled from "styled-components";

const Button = styled.div`
  color: #184c25;
  align-self: center;
  cursor: pointer;
  svg{font-size: 40px;};
  display: flex;
  flex-direction: column;
  :hover{
    color: white; 
    border-radius: 10px;
  }
  .tooltiptext{
    opacity: 0;
    background-color:  #184c25;
    padding: 4px;
    border-radius: 8px;
    position: absolute;
    font-size: 9px;
    bottom: -60%;
    max-width: 230%;
  }
  :hover .tooltiptext{
    opacity: 1;
  }
`;


export const ButtonNavigation = (props) => {
  return (
    <Button onClick={props.toGoPage} className={"tooltip ripple"}>
      {props.icon}
      <span className={"tooltiptext"}>{props.text}</span>
    </Button>
  );
};

import React, { useEffect, useState } from "react";

import { Card, TopCard, ContainerImage, Image, Details, Favorite, Description } from "./styled";
import casa from "../../images/casa.png";
import { formatedValue } from "../../hooks/FormatedValue";
import FavoriteColor from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

export const Cards = ({
  imovel,
  onCLickShowHouse,
  favorites,
  setFavorites,
}) => {
  const [active, setActive] = useState(false);
  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(imovel.files[0]);
  const [position, setPosition] = useState(0);

  useEffect(() => {
    const getFavoriteId = () => {
      const getId = favorites.includes(imovel.id);
      setActive(getId);
    };
    getFavoriteId();
  }, [imovel.id, favorites]);

  const favoriteOn = (id) => {
    const addFavorites = [...favorites, id];
    localStorage.setItem("favoritesTerraViva", JSON.stringify(addFavorites));

    setFavorites(addFavorites);
    setActive(true);
  };

  const favoriteOff = (id) => {
    const favoritesFilter = favorites.filter((favorite) => {
      return favorite !== id;
    });

    setFavorites(favoritesFilter);

    localStorage.removeItem("favoritesTerraViva");
    localStorage.setItem("favoritesTerraViva", JSON.stringify(favoritesFilter));

    setActive(false);
  };

  const nextImage = () => {
    const i = position + 1 === imovel.files.length ? 0 : position + 1;
    setPosition(i);
    if (imovel.files.length === 0) {
      setSelectedImage([]);
    }
  };

  const previousImage = () => {
    const i = position - 1 === imovel.files.length ? 0 : position - 1;

    if (position > 0) {
      setPosition(i);
    }
    if(position === 0){
      setPosition(imovel.files.length - 1)
    }
    if (imovel.files.length === 0) {
      setSelectedImage([]);
    }
  };

  useEffect(() => {
    setSelectedImage(imovel.files[position]);
  }, [position, imovel.files]);

  const tarjaStatus = imovel.status ? (
    ""
  ) : (
    <p className="tagStatus">
      {imovel.disponibilidade === "Alugar" ? "+1 ALUGADO" : "+1 VENDIDO"}
    </p>
  );

  return (
    <Card
      onClick={onCLickShowHouse}
      tamanho={descriptionOpen === false ? "350px" : "auto"}
    >
      <ContainerImage src={imovel.files.length ? selectedImage.url : casa}
          status={imovel.status}>
        <button
          onClick={previousImage}
          style={{ opacity: imovel.files.length <= 1 ? 0 : 1 }}
        >
          <ChevronLeftIcon />
        </button>
        <Image
          src={imovel.files.length ? selectedImage.url : casa}
          status={imovel.status}
        >
          {tarjaStatus}
        </Image>
        <button
          className="button-rigth"
          onClick={nextImage}
          style={{ opacity: imovel.files.length <= 1 ? 0 : 1 }}
        >
          <ChevronRightIcon />
        </button>
      </ContainerImage>

      <Details>
        <TopCard>
          <p className="titulo">{imovel.titulo}</p>
          <div className="codigoEfavorito">
            <p>código: {imovel.id}</p>
            <Favorite>
              {active === false ? (
                <FavoriteColor onClick={() => favoriteOn(imovel.id)} />
              ) : (
                <FavoriteIcon onClick={() => favoriteOff(imovel.id)} />
              )}
            </Favorite>
          </div>
        </TopCard>
        <p>
          {imovel.tipo} | {imovel.estado}
        </p>
        <div className="infos-row">
          <span>{imovel.uf}</span>
          <span>{imovel.cidade}</span>
          <span></span>
        </div>
        <div className="infos-row">
          {imovel.quartos === '00' ? "" : <p>{imovel.quartos} Quartos</p>}
          <p>Área contruída {imovel.area}m²</p>
          <p className="valor">{formatedValue(imovel.valor)}</p>
        </div>
      </Details>
      <Description position={descriptionOpen? 'column': 'column-reverse'}>
        <p style={{ display: descriptionOpen === true ? "flex" : "none" }}>
          {imovel.descricao}
        </p>
        <button
          onClick={() => setDescriptionOpen(!descriptionOpen)}
        >
        {descriptionOpen? (<><ExpandLessIcon/>Fechar</>) :(<>Saiba mais <ExpandMoreIcon /></>)}
        </button>
      </Description>
    </Card>
  );
};

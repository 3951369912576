import styled from "styled-components";

export const Main = styled.div`
  height: 76%;
  padding: 2%;
  @media(max-width: 450px){
    padding: 6% 2%;
    h1{
      margin-bottom: 2%;
    }
  }
`;
export const Section = styled.div`
  height: 90%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 1%;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  @media(max-width: 450px){
    grid-template-columns: 1fr;
    overflow: hidden;
  }
`;
export const Image = styled.div`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  //width: 100%;
  height: 220px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  margin: 6px;
  button {
    width: 24px;
    padding: 1%;
    margin: 1%;
    color: white;
    border: none;
    border-radius: 50%;
    font-weight: bolder;
    float: right;
    text-align: center;
    cursor: pointer;
    background-color: #dc3545;
  }
`;
export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    padding: 10px;
    border-radius: 10px;
    border: none;
    margin: 12px 10px;
    color: white;
    :focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }
  .adicionar {
    background-color: green;
  }
  .salvar {
    background-color: #216632;
  }
  .voltar{background-color: #216632;}
`;

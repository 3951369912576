import React from "react";
import { PageTitle } from "../../components/PageTitle";
import { Container } from '../../components/Container';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import styled from "styled-components";

const Main = styled.div`
    height: 100%;
    margin: 20%;
`;

export const ErrorPage = () => {
    return (
        <Container>
            <Header />
            <Main>
                <PageTitle text={"ERROR 404 - PAGE NOT FOUND"} />
            </Main>
            <Footer />
        </Container>
    );
};
import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export const BackDrop = ({open, text}) => {
  const classes = useStyles();
  
  return (
      <Backdrop className={classes.backdrop} open={open} >
        {text}
      </Backdrop>
  );
}
export const customStyles = {
  container: () => ({
    height: "46px",
    cursor: "pointer",
  }),
  control: () => ({
    height: "100%",
    fontSize: "15px",
    color: "#1a1a1d",
    background: "white",
    padding: "12px",
    border: "none",
    marginTop: "6px",
    borderRadius: "10px",
    boxShadow: "0px 3px 6px #00000029",
    resize: "none",
    display: "flex",
    "&:focus": {
      outline: 0,
      border: "1px solid #28a745",
    },
    ".react-select__placeholder": {
      position: "static",
      transform: "none",
    },
  }),
  valueContainer: () => ({
    height: "46px",
    width: "100%",
    position: "static",
  }),
  singleValue: () => ({
    position: "static",
    transform: "none",
  }),
  input: () => ({
    top: "-30%",
    border: "1px solid red",
    position: "absolute",
    color: "black",
  }),
  menu: () => ({
    top: "0",
    marginTop: "1%",
  }),
  menuList: () => ({
    background: "white",
    maxHeight: "160px",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "10px",
    overflowY: "auto",
    paddingBottom: "4px",
    paddingTop: "4px",
    position: "relative",
    boxSizing: "border-box",
  }),
};

import React from "react";
import { useHistory } from "react-router-dom";

import { ButtonNavigation } from "../../components/ButtonNavigation";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AddIcon from '@material-ui/icons/LibraryAdd';
import ReorderRoundedIcon from '@material-ui/icons/ReorderRounded';

export const Buttons = () => {
  const history = useHistory();

  const toGoListProperty = () => {
    history.push(`/lista-de-imoveis`);
  };
  const toGoAddProperty = () => {
    history.push(`/cadastrar-imovel`);
  };
  const toLogOut = () => {
    localStorage.removeItem('authorization');
    history.push(`/`);
  };
  return (
    <div style={{ display: "flex", gap: 10 }}>
      <ButtonNavigation toGoPage={toGoListProperty} icon={<ReorderRoundedIcon />} text={'Lista de Imóveis'}/>
      <ButtonNavigation toGoPage={toGoAddProperty} icon={<AddIcon />} text={'Adicionar Imóvel'}/>
      <ButtonNavigation toGoPage={toLogOut} icon={<ExitToAppIcon />} text={'Sair'}/>
    </div>
  );
};

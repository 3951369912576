import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import styled from "styled-components";

const getModalStyle = () => {
  const top = 40;
  const left = 40;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};
const Paper = styled.div`
  position: absolute;
  width: 70%;
  height: 80%;
  background-color: #dddd;
  border-radius: 1%;
  border: none;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4%;
  text-align: justify;
  p{
    margin-top: 2%;
    line-height: 150%;
    text-align: justify;
  }
  @media(max-width: 450px){
    width: 85%;
    height: 70%;
    h1{
      font-size: 16px;
      padding: 12px 0;
    }
    h3{
      font-size: 14px;
    }
    p{
      font-size: 12px;
    }
  }
`;
const ButtonClose = styled.button`
  width: 50px;
  height: 50px;
  background-color: transparent;
  border: none;
  position: absolute;
  right: 1%;
  top: 1%;
  font-weight: 900;
  font-size: x-large; 
`;
export const ModalPrivacyPolicy = (props) => {
  const [modalStyle] = useState(getModalStyle);

  const body = (
    <Paper style={modalStyle}>
      <ButtonClose onClick={() => props.setOpen(false)}>X</ButtonClose>
      <h1>Política de Cookies</h1>
      <h3>Informação sobre os ficheiros de cookies</h3>
      <p>
        O que é um cookie?
        Um cookie é um pequeno ficheiro de texto que um site coloca no seu computador, telefone ou qualquer outro dispositivo com acesso à internet, contendo informação sobre a sua navegação nesse site.
        Os cookies retêm apenas informação relacionada com as suas preferências.
        Para que servem os ficheiros de cookies?
        Os cookies são utilizados para facilitar a navegação e torná-la mais simples e não danificam o seu dispositivo. Permitem uma navegação mais rápida e eficiente, eliminando a necessidade de introduzir repetidamente as mesmas informações.
        É possível desactivar a utilização de cookies?
        Sim, os cookies podem ser desactivados no seu navegador. Note-se, no entanto, que, ao desactivar os cookies, pode impedir que alguns serviços da web funcionem correctamente, afectando, parcial ou totalmente, a navegação no website.
        Se pretende bloquear ou apagar os cookies deste site, pode fazê-lo modificando a configuração do seu navegador. Embora a parametrização de cada um seja diferente, é comum que se configure no menu de "Preferências" ou "Ferramentas". Para mais detalhes sobre a configuração dos cookies, consulte o menu "Ajuda" do seu navegador.
      </p>
    </Paper>
  );

  return (
    <Modal
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

import * as yup from "yup";
import React, { useRef, useState } from "react";
import axios from "axios";

import { PageTitle } from "../../components/PageTitle";
import { Form, SubTitle, Section } from "./styles";
import { Container } from "../../components/Container";
import { Input } from "../../components/Input";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { UploadImages } from "./UploadImages";
import { TextArea } from "../../components/TextArea";
import { schema } from "./validation";
import { baseUrl } from "../../Constant";
import { ButtonListProperty } from "../../components/ButtonListProperty";
import { useHistory } from "react-router-dom";
import { BackDrop } from "../../components/BackDrop";
import { CircularProgress } from "@material-ui/core";
import { useProtectedPage } from "../../hooks/useProtectedPage";
import { Select } from "../../components/Select";
import { estado, finalidade, quartos, tipoDoImovel } from "../../data";
import { Inputmask } from "../../components/InputMask";
import { InputValue } from "../../components/InputValue";

export const RegisterPropertyPage = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const formRef = useRef(null);

  const token = localStorage.getItem('authorization');

  useProtectedPage();

  const onBlurCep = (ev) => {
    const { value } = ev.target;
    let cep = value?.replace(/[^0-9]/g, "");
    if (cep?.length !== 8) {
      return;
    }
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((response) => response.json())
      .then((data) => {
        formRef.current.setFieldValue("endereco", data.logradouro);
        formRef.current.setFieldValue("bairro", data.bairro);
        formRef.current.setFieldValue("cidade", data.localidade);
        formRef.current.setFieldValue("uf", data.uf);
      });
  };

  const onSubmitProperty = async (data) => {
    try {
      
      data.valor = data.valor
        .replace("R$", "")
        .replace(/\./g, "")
        .replace(/,/g, ".");

      const listFileURL = [];
      const promises = uploadedFiles.map(async (file) => {
        const fileType = file.type.slice(6);
        
        const { data } = await axios.get(`${baseUrl}/upload`, {
          headers: {
            auth: `${token}`,
          },
          params: {
            fileType
          }
        });

        await axios.put(data.signed_request_url, file);

        listFileURL.push({ id: data.imageId, url: data.result_url });
      });

      await Promise.all(promises);

      const body = {
        titulo: data.titulo,
        tipo: data.tipo,
        disponibilidade: data.disponibilidade,
        quartos: data.quartos,
        estado: data.estado,
        area: data.area,
        valor: parseFloat(data.valor),
        proprietario: data.proprietario,
        contato: data.contato,
        cep: data.cep,
        cidade: data.cidade,
        endereco: data.endereco,
        bairro: data.bairro,
        uf: data.uf,
        descricao: data.descricao,
        status: true,
        files: listFileURL
      }
      //console.log(body);

      setOpen("loading");

      await schema.validate(data, {
        abortEarly: false,
      });
      formRef.current.setErrors({});
      
      await axios.post(`${baseUrl}/properties`, body, {
        headers: {
          auth: token,
        },
      });

      setOpen("Seu Imovel foi cadastrado com sucesso");

      setTimeout(() => {
        history.push("/lista-de-imoveis");
      }, 1000);
    } catch (err) {
      setOpen("Algum erro aconteceu");

      setTimeout(() => {
        setOpen(false);
      }, 1000);

      console.log("Deu erro", err);
      const validationErrors = {};

      if (err instanceof yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      } else if (err.response.status >= 400) {
        setErrorMessage(err.response.data.mensagem);
      }
    }
  };
  return (
    <Container>
      <Header button={<ButtonListProperty />} />
      <Section ref={formRef} onSubmit={onSubmitProperty}>
        <PageTitle text={"Cadastro de Imóvel"} className="long" />
        <Form>
          <SubTitle className="long">CARACTÉRISTICAS DO IMÓVEL</SubTitle>
          {
            <p
              className="long"
              style={{
                color: "#F00",
                textAlign: "center",
                textTransform: "Uppercase",
              }}
            >
              {errorMessage}
            </p>
          }
          <Input label="Titulo do Imóvel" name="titulo" className="long" />
          <Select
            label="Tipo do Imóvel"
            name="tipo"
            className="short"
            placeholder="Selecione..."
            options={tipoDoImovel}
          />
          <Select
            label="Disponibilidade"
            name="disponibilidade"
            className="short"
            placeholder="Selecione..."
            options={finalidade}
          />
          <Select
            label="Quantidade de quartos"
            name="quartos"
            className="short"
            placeholder="Selecione..."
            options={quartos}
          />
          <Select
            label="Estado"
            name="estado"
            className="short"
            placeholder="Selecione..."
            options={estado}
          />
          <Input
            label="Área construída"
            name="area"
            className="short"
          />
          <InputValue
            label="Valor"
            name="valor"
            className="short"
            id="inputValor"
          />
          <Input label="Proprietário" name="proprietario" className="short" />
          <Inputmask
            label="Contato"
            name="contato"
            className="short"
            type="tel"
            mask="(99) 9 9999-9999"
          />
          <UploadImages
            label="Anexos"
            name="anexo"
            className="short"
            setUploadedFiles={setUploadedFiles}
            uploadedFiles={uploadedFiles}
          />

          <SubTitle className="long">ENDEREÇO/LOCALIZAÇÃO</SubTitle>
          <Input label="CEP" name="cep" onBlur={onBlurCep} className="short" />
          <Input
            label="Cidade"
            name="cidade"
            className="short"
            disabled="disabled"
          />
          <Input
            label="Rua"
            name="endereco"
            className="long"
            disabled="disabled"
          />
          <Input
            label="Bairro"
            name="bairro"
            className="long"
            disabled="disabled"
          />
          <Input label="UF" name="uf" className="long" disabled="disabled" />
          <TextArea
            className="long row-anexo"
            label="Descrição"
            component={"textarea"}
            rows="6"
            name="descricao"
            type="text"
            placeholder="Descreva aqui dados complementares do seu imóvel para visualização dos clientes."
          />
        </Form>
        <button type="submit" className="ripple">
          SALVAR
        </button>

        {open === "loading" ? (
          <BackDrop open={open} text={<CircularProgress color="inherit" />} />
        ) : (
          <BackDrop open={open} text={open} />
        )}
      </Section>
      <Footer />
    </Container>
  );
};

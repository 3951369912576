import React from "react";
import styled from 'styled-components';
import logo from '../images/logo_white.png';

const NavContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #3BB358;
    padding: 10px;
    img{
        height: 70px;
    }
    .button{
        position: absolute;
        align-self: center;
        right: 3%;
    }
`;

export const Nav = (props) => {
    return (
        <NavContainer className="nav">
            <a href="/"><img src={logo} alt="logo" /></a>
            <div className="button">
                {props.button}
            </div>
        </NavContainer>
    );
};
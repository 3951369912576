import styled, { css } from "styled-components";

export const Content = styled.div`
  align-self: center;
  min-height: 46px;
  width: 100%;
  font-size: 15px;
  margin-top: 22px;
  border-radius: 10px;
  label {
    margin: 0;
  }
`;
const dragActive = css`
  border-color: #78e5d5;
`;

const dragReject = css`
  border-color: #e57878;
`;

export const DropContainer = styled.div.attrs({
  className: "dropzone",
})`
  width: 100%;
  height: 46px;
  padding: 12px;
  margin-top: 6px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 15px;
  transition: height 0.2s ease;
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  ${(props) => props.isDragActive && dragActive};
  ${(props) => props.isDragReject && dragReject};
`;
const messageColors = {
  default: "#4EAD8F",
  error: "#dc3545",
  success: "#78e5d5",
};

export const UploadMessage = styled.p`
  height: 100%;
  display: flex;
  color: ${(props) => messageColors[props.type || "default"]};
  justify-content: center;
  align-items: center;
  font-weight: bold;
`;

export const FileListContainer = styled.ul`
  margin-top: 6px;
  li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #4ead8f;
    font-size: 10px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: none;
    button {
      justify-self: center;
      font-size: 10px;
      font-weight: bolder;
      border: none;
      background-color: transparent;
      color: #e57878;
      max-width: 12px;
      box-shadow: none;
      margin: 0;
      cursor: pointer;
    }
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0px 8px;
    }
    & + li {
      margin-top: 6px;
    }
  }
`;

export const FileInfo = styled.div`
  width: 100%;
  span {
    max-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

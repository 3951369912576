import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { ModalImageZoom } from "../../components/ModalImageZoom";
import casa from "../../images/casa.png";
import Modal from "react-modal";

Modal.setAppElement("#root");
const Container = styled.div`
  height: 60%;
  display: flex;
  flex-direction: column;
`;

const ImagePrincipal = styled.div`
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  cursor: zoom-in;
  button {
    position: absolute;
    height: 30px;
    width: 30px;
    padding: 5px;
    background: #e0e8ea;
    border: none;
    margin: 15px;
    font-weight: bolder;
    border-radius: 50px;
    color: #216632;
    text-align: center;
    cursor: pointer;
    :hover {
      background-color: #ffffff;
    }
  }
  .button-rigth {
    right: 15px;
  }
  svg {
    height: 100%;
    width: 100%;
    text-align: center;
  }
  path {
    height: 50px;
  }
`;

const Image = styled.div`
  transition: all 0.4s;
  height: 100%;
  width: 100%;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
  p {
    color: white;
    font-weight: bold;
    text-align: center;
    width: 100%;
    top: 70%;
    background-color: #dc3545;
    padding: 2%;
    opacity: 1;
    position: relative;
  }
`;

const Slide = styled.div`
  display: -webkit-box;
  width: 100%;
  width: auto;
  overflow: auto;
  height: 20%;

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #3bb358;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #216632;
  }
`;
const Miniaturas = styled.div`
  display: block;
  max-height: 100%;
  width: 15%;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
  margin: 4px;
  cursor: pointer;
`;

export const Carousel = ({ files, house }) => {
  const [selectedImage, setSelectedImage] = useState(files[0]);
  const [position, setPosition] = useState(0);
  const [open, setOpen] = useState(false);

  const nextImage = () => {
    const i = position + 1 === files.length ? 0 : position + 1;
    setPosition(i);
    if (files.length === 0) {
      setSelectedImage([]);
    }
  };
  const previousImage = () => {
    const i = position - 1 === files.length ? 0 : position - 1;

    if (position > 0) {
      setPosition(i);
    }
    if(position === 0){
      setPosition(files.length-1)
    }

    if (files.length === 0) {
      setSelectedImage([]);
    }
  };

  useEffect(() => {
    setSelectedImage(files[position]);
  }, [position, files]);

  return (
    <Container>
      {files.length === 1 ? (
        <Image
          src={files[0].url}
          status={house.status}
          onClick={() => setOpen(true)}
        >
          {house.status ? (
            ""
          ) : (
            <p>
              {house.disponibilidade === "Alugar" ? "+1 ALUGADO" : "+1 VENDIDO"}
            </p>
          )}
        </Image>
      ) : (
        <Image status={house.status}>
          <ImagePrincipal>
            <button
              onClick={previousImage}
              style={{ opacity: files.length === 1 ? 0 : 1 }}
            >
              <ChevronLeftIcon />
            </button>
            <Image
              src={selectedImage === undefined ? casa : selectedImage.url}
              onClick={() => setOpen(true)}
              status={house.status}
            >
              {house.status ? (
                ""
              ) : (
                <p>
                  {house.disponibilidade === "Alugar"
                    ? "+1 ALUGADO"
                    : "+1 VENDIDO"}
                </p>
              )}
            </Image>
            <button
              className="button-rigth"
              onClick={nextImage}
              style={{ opacity: files.length === 1 ? 0 : 1 }}
            >
              <ChevronRightIcon />
            </button>
          </ImagePrincipal>
          <Slide>
            {files.map((image) => {
              return (
                <Miniaturas
                  src={image.url}
                  key={image.id}
                  alt="miniatura de casa"
                  style={{ opacity: image.id === selectedImage.id ? 1 : 0.6 }}
                  onClick={() => setSelectedImage(image)}
                />
              );
            })}
          </Slide>
        </Image>
      )}
      <ModalImageZoom
        open={open}
        setOpen={setOpen}
        previousImage={previousImage}
        nextImage={nextImage}
        selectedImage={selectedImage}
      />
    </Container>
  );
};

import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body, input, select, textarea, button{
    font-family: 'Montserrat', sans-serif;
  }
  ::-webkit-input-placeholder{ font-family: 'Montserrat', sans-serif; }
  input:-moz-placeholder{ font-family: 'Montserrat', sans-serif; }
  textarea:-moz-placeholder{ font-family: 'Montserrat', sans-serif; }
  input[type=number]::-webkit-inner-spin-button { 
    -webkit-appearance: none;  
  }
  button, div{
    :focus{
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }
  button{ 
    cursor: pointer;
    :hover{
      transition: 0.5s;
    }
  }
  .ripple {
    background-position: center;
    transition: background 0.8s;
  }
  .ripple:hover {
    background: #28a745 radial-gradient(circle, transparent 1%, #28a745 1%)
      center/15000%;
  }
  .ripple:active {
    background-color: #28a745;
    background-size: 100%;
    transition: background 0s;
  }

  .ReactModal__Body--open{
    color: red;
    .ReactModal__Content--after-open{max-width: 90%;}
  }
`;

import React from "react";
import { useHistory } from "react-router-dom";
import styled from 'styled-components';
import { Container } from "../../components/Container";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { PageTitle } from "../../components/PageTitle";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10%;
    position: relative;
    height: 100%;
    width: 100%;
    button{
        padding: 15px;
        width: 250px;
        border: none;
        border-radius: 10px;
        margin: 5%;
        background-color: #28A745;
        color: #FFFFFF;
        box-shadow: 0px 3px 6px #00000029;
        :hover{
            background-color: #216632;
        }
    }
`;

export const PageSucess = () => {
    const history = useHistory();

    const toGoBack = () =>{
        history.push("/")
    }
    return (
        <Container height={"100vh"}>
            <Header/>
            <Main>
                <PageTitle text="Dados enviados com sucesso"/>
                <button onClick={toGoBack}>Voltar para nosso site</button>
            </Main>
            <Footer/>
        </Container>
    );
};
import { Form } from "@unform/web";
import styled from "styled-components";

export const Main = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  label {
    text-align: start;
    width: 30%;
    margin-top: 22px;
    font-size: 15px;
    color: #216632;
    text-shadow: 0px 1px 3px #00000029;
  }
  input,
  textarea {
    height: 46px;
    width: 30%;
    font-size: 15px;
    color: #1a1a1d;
    padding: 12px;
    border: none;
    margin-top: 6px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    resize: none;
    &:focus {
      outline: 0;
      border: 1px solid #28a745;
    }
  }
  textarea {
    height: 150px;
  }
  button {
    padding: 1%;
    width: 30%;
    border: none;
    border-radius: 10px;
    margin: 2%;
    background-color: #28a745;
    color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    :hover {
      background-color: #216632;
    }
  }
  p {
    font-size: 12px;
    width: 30%;
    text-align: left;
  }
  @media (min-width: 280px) and (max-width: 480px) {
    input,
    label,
    button,textarea {
      width: 100%;
    }
    button{
      margin-top: 20px;
      height: 40px;
    }
  }
  @media (min-width: 540px) and (max-width: 720px) {
    input,
    label,
    button,textarea {
      width: 100%;
    }
  }
`;

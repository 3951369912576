import React from "react";
import { useHistory } from "react-router-dom";

import { ButtonNavigation } from "../../components/ButtonNavigation";
import ContactsIcon from "@material-ui/icons/Contacts";
import AddIcon from '@material-ui/icons/LibraryAdd';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

export const Buttons = () => {
  const history = useHistory();

  const toGoListContacts = () => {
    history.push(`/lista-de-contatos`);
  };
  const toGoAddProperty = () => {
    history.push(`/cadastrar-imovel`);
  };
  const toLogOut = () => {
    localStorage.removeItem('authorization');
    history.push(`/`);
  };
  return (
    <div style={{ display: 'flex', gap: 10 }}>
      <ButtonNavigation toGoPage={toGoListContacts} icon={<ContactsIcon />} text={'Lista de Contatos'}/>
      <ButtonNavigation toGoPage={toGoAddProperty} icon={<AddIcon />} text={'Adicionar Imóvel'}/>
      <ButtonNavigation toGoPage={toLogOut} icon={<ExitToAppIcon />} text={'Sair'}/>
    </div>
  );
};

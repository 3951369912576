import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  root: props => ({
    width: props.width? props.width : 500,
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    height: 40,
    borderRadius: 10,
    "@media(max-width: 450px)": {
      margin: "0 12px",
      width: props.width? props.width : '100%'
    },
  }),
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: "8px 10px",
  },
}));

export const SearchBar = (props) => {
  const classes = useStyles(props);

  return (
    <Paper
      className={classes.root}
      onSubmit={props.search}
      component="form"
    >
      <InputBase
        className={classes.input}
        placeholder={props.text}
        value={props.searchValue}
        onChange={(e) => props.setSearchValue(e.target.value)}
      />
      <IconButton
        type="submit"
        className={classes.iconButton}
        aria-label="search"
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

export const Input = (props) => {
    const inputRef = useRef(null);
    const { fieldName, registerField, defaultValue, error } = useField(props.name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value'
        })
    }, [fieldName, registerField]);

    return(
        <div className={props.className} >
            {props.label && <label htmlFor={fieldName}>{props.label}</label>}
            <input 
                id={props.id}
                disabled={props.disabled} 
                ref={inputRef} 
                type={props.type} 
                defaultValue={defaultValue} 
                {...props.rest} 
                onBlur={props.onBlur}
                placeholder={props.placeholder}
            />
            
            {error && <p style={{ color: '#F00'}}>{error}</p>}
        </div>
    );
}
import * as yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";

import { PageTitle } from "../../components/PageTitle";
import { Form, SubTitle, Section } from "../RegisterPropertyPage/styles";
import { Container } from "../../components/Container";
import { Input } from "../../components/Input";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { TextArea } from "../../components/TextArea";
import { schema } from "../RegisterPropertyPage/validation";
import { baseUrl } from "../../Constant";
import { ButtonListProperty } from "../../components/ButtonListProperty";
import { useProtectedPage } from "../../hooks/useProtectedPage";
import { SelectEdit } from "./SelectEdit";
import { estado, finalidade, tipoDoImovel, quartos, status } from "../../data";
import { Inputmask } from "../../components/InputMask";
import { BackDrop } from "../../components/BackDrop";
import { CircularProgress } from "@material-ui/core";
import { InputValueEdit } from "./InputValueEdit";
import { formatedValue } from "../../hooks/FormatedValue";

export const EditPropertyPage = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [dataValue, setDataValue] = useState();
  // const [dataType, setDataType] = useState();
  // const [dataFinality, setDataFinality] = useState();
  // const [dataState, setDataState] = useState();
  // const [dataRoom, setDataRoom] = useState();
  // const [dataStatus, setDataStatus] = useState();
  const history = useHistory();
  const formRef = useRef(null);
  let { id } = useParams();

  const token = localStorage.getItem("authorization");

  useProtectedPage();

  useEffect(() => {
    const getPropertyId = async () => {
      const { data } = await axios.get(`${baseUrl}/properties/${id}`);

      setDataValue(formatedValue(data.valor));
      // setDataType({ value: data.tipo, label: data.tipo });
      // setDataFinality({
      //   value: data.disponibilidade,
      //   label: data.disponibilidade,
      // });
      // setDataState({ value: data.estado, label: data.estado });
      // setDataRoom({ value: data.quartos, label: data.quartos });
      // setDataStatus({
      //   value: data.status,
      //   label: data.status ? "Disponível" : "Indisponível",
      // });

      formRef.current.setData({
        titulo: data.titulo,
        tipo: { value: data.tipo, label: data.tipo },
        disponibilidade: {
          value: data.disponibilidade,
          label: data.disponibilidade,
        },
        estado: { value: data.estado, label: data.estado },
        quartos: { value: data.quartos, label: data.quartos },
        area: data.area,
        valor: formatedValue(data.valor),
        proprietario: data.proprietario,
        contato: data.contato,
        cep: data.cep,
        cidade: data.cidade,
        endereco: data.endereco,
        bairro: data.bairro,
        uf: data.uf,
        descricao: data.descricao,
        status: {
          value: data.status,
          label: data.status ? "Disponível" : "Indisponível",
        },
      });
    };
    getPropertyId();
  }, [id]);

  const onBlurCep = (ev) => {
    const { value } = ev.target;
    let cep = value?.replace(/[^0-9]/g, "");
    if (cep?.length !== 8) {
      return;
    }
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((response) => response.json())
      .then((data) => {
        formRef.current.setFieldValue("endereco", data.logradouro);
        formRef.current.setFieldValue("bairro", data.bairro);
        formRef.current.setFieldValue("cidade", data.localidade);
        formRef.current.setFieldValue("uf", data.uf);
      });
  };

  const onSubmitProperty = async (data) => {
    
    try {
      setOpen("loading");

      data.valor = data.valor
        .replace("R$", "")
        .replace(/\./g, "")
        .replace(/,/g, ".");

      const body = {
        titulo: data.titulo,
        tipo: data.tipo,
        disponibilidade: data.disponibilidade,
        quartos: data.quartos,
        estado: data.estado,
        area: data.area,
        valor: parseFloat(data.valor),
        proprietario: data.proprietario,
        contato: data.contato,
        cep: data.cep,
        cidade: data.cidade,
        endereco: data.endereco,
        bairro: data.bairro,
        uf: data.uf,
        descricao: data.descricao,
        status: data.status,
      };

      await schema.validate(data, {
        abortEarly: false,
      });
      formRef.current.setErrors({});

      await axios.put(`${baseUrl}/properties/${id}`, body, {
        headers: {
          auth: token,
        },
      });

      setOpen("Seu Imovel foi editado com sucesso");

      setTimeout(() => {
        history.push("/lista-de-imoveis");
      }, 1000);
    } catch (err) {
      setOpen("Algum erro aconteceu");

      setTimeout(() => {
        setOpen(false);
      }, 1000);

      console.log("Deu erro", err);
      const validationErrors = {};

      if (err instanceof yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      } else if (err.response.status >= 400) {
        setErrorMessage(err.response.data.mensagem);
      }
      console.log("validationErrors", validationErrors);
    }
  };

  return (
    <Container>
      <Header button={<ButtonListProperty />} />
      <Section ref={formRef} onSubmit={onSubmitProperty}>
        <PageTitle text={"Editar Cadastro"} className="long" />
        <Form>
          <SubTitle className="long">CARACTÉRISTICAS DO IMÓVEL</SubTitle>
          {
            <p
              className="long"
              style={{
                color: "#F00",
                textAlign: "center",
                textTransform: "Uppercase",
              }}
            >
              {errorMessage}
            </p>
          }
          <Input label="Titulo do Imóvel" name="titulo" className="long" />
          <SelectEdit
            label="Tipo do Imóvel"
            name="tipo"
            className="short"
            options={tipoDoImovel}
          />
          <SelectEdit
            label="Disponibilidade"
            name="disponibilidade"
            className="short"
            options={finalidade}
          />
          <SelectEdit
            label="Quantidade de quartos"
            name="quartos"
            className="short"
            options={quartos}
          />
          <SelectEdit
            label="Estado"
            name="estado"
            className="short"
            options={estado}
          />
          <Input label="Área Construida" name="area" className="short" />
          <InputValueEdit
            label="Valor"
            name="valor"
            className="short"
            dataValue={dataValue}
            setDataValue={setDataValue}
          />
          <Input label="Proprietário" name="proprietario" className="short" />
          <Inputmask
            label="Contato"
            name="contato"
            className="short"
            type="tel"
            mask="(99) 9 9999-9999"
          />
          <SelectEdit
            label="Status"
            name="status"
            className="short"
            options={status}
          />
          <SubTitle className="long">ENDEREÇO/LOCALIZAÇÃO</SubTitle>
          <Input label="CEP" name="cep" onBlur={onBlurCep} className="short" />
          <Input
            label="Cidade"
            name="cidade"
            className="short"
            disabled="disabled"
          />
          <Input
            label="Rua"
            name="endereco"
            className="long"
            disabled="disabled"
          />
          <Input
            label="Bairro"
            name="bairro"
            className="long"
            disabled="disabled"
          />
          <Input label="UF" name="uf" className="long" disabled="disabled" />
          <TextArea
            className="long row-anexo"
            label="Descrição"
            component={"textarea"}
            rows="6"
            name="descricao"
            type="text"
            placeholder="Descreva aqui dados complementares do seu imóvel para visualização dos clientes."
          />
        </Form>
        <button type="submit" className="ripple">
          SALVAR
        </button>
        {open === "loading" ? (
          <BackDrop open={open} text={<CircularProgress color="inherit" />} />
        ) : (
          <BackDrop open={open} text={open} />
        )}
      </Section>
      <Footer />
    </Container>
  );
};

import React, { useRef, useEffect } from 'react';

import { useField } from '@unform/core';
import NumberFormat from 'react-number-format';

export const InputValueEdit = ({ label, name, placeholder, disabled, dataValue, setDataValue, className, ...rest }) => {
    const inputRef = useRef(null);
    const { fieldName, registerField, defaultValue , error } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value'
        });
        
    }, [fieldName, registerField, defaultValue]);

    const handleChange = (event) => {
        let { value } = event.target;

        if(!Number(value)){
            value = value
            .replace("R$", "")
            .replace(/\./g, "")
            .replace(/,/g, "");
        }

        setDataValue(value);
    };

    const currencyFormatter = (value) => {
        if (!Number(value)) return "";

        const amount = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL"
        }).format(value / 100);
        return `${amount}`;
    }

    return (
        <div className={className}>
            {label && <label htmlFor={fieldName}>{label}</label>}
            <NumberFormat 
                getInputRef={inputRef}  
                value={dataValue}
                format={currencyFormatter}
                defaultValue={defaultValue} 
                onChange={(event) => handleChange(event)}
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale={true}
                placeholder={placeholder}
                disabled={disabled}
                {...rest}
            />
            
            {error && <p style={{ color: '#F00'}}>{error}</p>}
        </div>
    );
};
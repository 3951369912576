import React, { useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";

import { PageTitle } from "../../components/PageTitle";
import { Form, SubTitle, Section } from "../RegisterPropertyPage/styles";
import { Container } from "../../components/Container";
import { Input } from "../../components/Input";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { TextArea } from "../../components/TextArea";
import { baseUrl } from "../../Constant";
import { ButtonListProperty } from "../../components/ButtonListProperty";
import { useProtectedPage } from "../../hooks/useProtectedPage";
import { estado, finalidade, tipoDoImovel, quartos } from "../../data";
import { Inputmask } from "../../components/InputMask";
import { InputValue } from "../../components/InputValue";
import { formatedValue } from "../../hooks/FormatedValue";

export const DetailsPropertyPage = () => {
  const history = useHistory();
  
  const formRef = useRef(null);
  let { id } = useParams();

  useProtectedPage();

  useEffect(() => {
    const getPropertyId = async () => {
      const { data } = await axios.get(`${baseUrl}/properties/${id}`);
      formRef.current.setData({
        titulo: data.titulo,
        tipo: data.tipo,
        disponibilidade: data.disponibilidade,
        estado: data.estado,
        quartos: data.quartos,
        area: data.area,
        valor: formatedValue(data.valor),
        proprietario: data.proprietario,
        contato: data.contato,
        cep: data.cep,
        cidade: data.cidade,
        endereco: data.endereco,
        bairro: data.bairro,
        uf: data.uf,
        descricao: data.descricao,
        status: data.status
      });
    };
    getPropertyId();
  }, [id]);

  const toBackPage = () => {
    history.push(`/lista-de-imoveis`);
  };

  return (
    <Container>
      <Header button={<ButtonListProperty />} />
      <Section ref={formRef}>
        <PageTitle text={"Detalhes do Imovél"} className="long" />
        <Form>
          <SubTitle className="long">CARACTÉRISTICAS DO IMÓVEL</SubTitle>
          <Input
            label="Titulo do Imóvel"
            name="titulo"
            className="long"
            disabled="disabled"
          />
          <Input
            label="Tipo do Imóvel"
            name="tipo"
            className="short"
            options={tipoDoImovel}
            disabled="disabled"
          />
          <Input
            label="Disponibilidade"
            name="disponibilidade"
            className="short"
            options={finalidade}
            disabled="disabled"
          />
          <Input
            label="Quantidade de quartos"
            name="quartos"
            className="short"
            options={quartos}
            disabled="disabled"
          />
          <Input
            label="Estado"
            name="estado"
            className="short"
            options={estado}
            disabled="disabled"
          />
          <Input
            label="Área Construida"
            name="area"
            className="short"
            disabled="disabled"
          />
          <InputValue
            label="Valor"
            name="valor"
            className="short"
            disabled="disabled"
          />
          <Input
            label="Proprietário"
            name="proprietario"
            className="short"
            disabled="disabled"
          />
          <Inputmask
            label="Contato"
            name="contato"
            className="short"
            type="tel"
            mask="(99) 9 9999-9999"
            disabled="disabled"
          />
          <SubTitle className="long">ENDEREÇO/LOCALIZAÇÃO</SubTitle>
          <Input label="CEP" name="cep" className="short" disabled="disabled" />
          <Input
            label="Cidade"
            name="cidade"
            className="short"
            disabled="disabled"
          />
          <Input
            label="Rua"
            name="endereco"
            className="long"
            disabled="disabled"
          />
          <Input
            label="Bairro"
            name="bairro"
            className="long"
            disabled="disabled"
          />
          <Input label="UF" name="uf" className="long" disabled="disabled" />
          <TextArea
            className="long row-anexo"
            label="Descrição"
            component={"textarea"}
            rows="6"
            name="descricao"
            type="text"
            placeholder="Descreva aqui dados complementares do seu imóvel para visualização dos clientes."
            disabled="disabled"
          />
        </Form>
        <button onClick={toBackPage}>VOLTAR</button>
      </Section>
      <Footer />
    </Container>
  );
};

import React, { useEffect, useState } from "react";
import { PageTitle } from "../../components/PageTitle";
import { Main, Section, Image, Buttons } from "./styles";
import { Container } from "../../components/Container";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { baseUrl } from "../../Constant";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { ModalDelete } from "../../components/ModalDelete";
import { ModalAddFile } from "./ModalAddFile";
import { BackDrop } from "../../components/BackDrop";
import { CircularProgress } from "@material-ui/core";
import { useProtectedPage } from "../../hooks/useProtectedPage";
import Modal from "react-modal";

Modal.setAppElement("#root");
export const EditImage = () => {
  const [files, setFiles] = useState([]);
  const [fileId, setFileId] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalIsOpenDel, setIsOpenDel] = useState(false);
  const [modalIsOpenAdd, setIsOpenAdd] = useState(false);
  const history = useHistory();
  let { id } = useParams();

  const token = localStorage.getItem("authorization");

  useProtectedPage();

  useEffect(() => {
    getFilesId();
  }, [id]);

  const getFilesId = async () => {
    setOpen("loading");
    const response = await axios.get(`${baseUrl}/properties/${id}`);
    setFiles(response.data.files);
    setOpen(false);
  };

  const confirmeDel = (fileId) => {
    setFileId(fileId);
    setIsOpenDel(true);
  };

  const saveFilesOrBack = () => {
    history.push("/lista-de-imoveis");
  };

  const deleteFile = async () => {
    setOpen("loading");

    await axios.delete(`${baseUrl}/properties/${id}/file/${fileId}`, {
      headers: {
        auth: token,
      },
    });

    setIsOpenDel(false);

    setOpen("Imagem apagada!");

    setOpen(false);

    setFiles(
      files.filter((file) => {
        return file.id !== fileId;
      })
    );
  };

  const createFile = async () => {
    try {
      setOpen("loading");
      const listFileURL = files;
      const promises = uploadedFiles.map(async (file) => {
        const fileType = file.type.slice(6);
        
        const { data } = await axios.get(`${baseUrl}/upload`, {
          headers: {
            auth: `${token}`,
          },
          params: {
            fileType
          }
        });

        await axios.put(data.signed_request_url, file);

        listFileURL.push({ id: data.imageId, url: data.result_url });
      });

      await Promise.all(promises);

      //console.log("-->", listFileURL);
      await axios.post(`${baseUrl}/properties/${id}/file`, listFileURL, {
        headers: {
          auth: `${token}`,
        },
      });

      setOpen("Imagens adicionadas com sucesso!");

      setTimeout(() => {
        setOpen(false);
        setIsOpenAdd(false);
        setUploadedFiles([]);
      }, 1000);
    } catch (err) {
      console.log(err);
      setOpen("Algum erro aconteceu");

      setTimeout(() => {
        setOpen(false);
      }, 1000);
    }
  };

  const cancelUpload = () => {
    setIsOpenAdd(false);
    setUploadedFiles([]);
  };

  return (
    <Container>
      <Header />
      <Main>
        <PageTitle text={"Edição de Imagens"} />
        <Section>
          {files.map((file) => {
            return (
              <Image key={file.id} src={file.url}>
                <button onClick={() => confirmeDel(file.id)}>X</button>
              </Image>
            );
          })}
        </Section>
        <Buttons>
          <button className="adicionar" onClick={() => setIsOpenAdd(true)}>
            ADICIONAR IMAGEM
          </button>
          <button className="voltar" onClick={saveFilesOrBack}>
            VOLTAR
          </button>
          <button className="salvar" onClick={saveFilesOrBack}>
            SALVAR
          </button>
        </Buttons>
        <ModalDelete
          setIsOpen={setIsOpenDel}
          modalIsOpen={modalIsOpenDel}
          deleteItem={deleteFile}
          text={"Tem certeza que deseja deletar essa Imagem?"}
        />
        <ModalAddFile
          setIsOpen={setIsOpenAdd}
          modalIsOpen={modalIsOpenAdd}
          createFile={createFile}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          cancelUpload={cancelUpload}
        />
      </Main>
      <Footer />
      {open === "loading" ? (
        <BackDrop open={open} text={<CircularProgress color="inherit" />} />
      ) : (
        <BackDrop open={open} text={open} />
      )}
    </Container>
  );
};

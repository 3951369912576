import styled from "styled-components";

export const Main = styled.div`
  align-self: center;
  justify-self: center;
  width: 95%;
  height: 78%;
  .nav {
    display: flex;
    justify-content: space-between;
    padding: 1%;
    height: 10%;
    svg {
      color: #4ead8f;
    }
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
    height: 1%;
    padding: 1%;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    .nav {
      flex-direction: column;
      align-items: center;
      margin: 0 4px;
      h1 {
        margin: 12px 0;
        text-align: center;
        font-size: 22px;
      }
      div {
        max-width: 100%;
      }
    }
    .pagination {
      justify-content: center;
      padding: 5%;
    }
  }
`;
export const Section = styled.div`
  width: 100%;
  height: 78%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(6, 1fr);
  overflow: auto;
  margin-top: 1%;

  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`;
export const Card = styled.div`
  background-color: white;
  width: 96%;
  max-height: 150px;
  margin: 8px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  .barra-do-menu {
    display: flex;
    padding: 2px 10px;
    button {
      padding: 0;
    }
    justify-content: space-between;
    div {
      display: flex;
      align-items: center;
    }
  }
  .status {
    height: 15px;
    width: 15px;
    border-radius: 50px;
    margin-right: 10px;
  }
  .codigo {
    align-self: center;
    font-size: 10px;
    font-weight: bolder;
    color: #216632;

    max-width: 100.2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    :hover {
      text-overflow: clip;
      max-width: none;
    }
  }
`;
export const Info = styled.div`
  padding: 8px 12px;
  display: flex;
  border-top: 1px solid green;
  color: #8c8b92;
  .titulo {
    font-size: 14px;
    color: #216632;
    font-weight: bolder;
  }
  .valor {
    color: #216632;
    font-weight: bolder;
  }
`;
export const Image = styled.div`
  height: 100px;
  width: 120px;
  border-radius: 10px;
  background-image: url(${(props) => props.src});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  p {
    color: white;
    font-weight: bold;
    text-align: center;
    width: 100%;
    margin-top: 60px;
    background-color: #dc3545;
    padding: 5px;
    opacity: 1;
  }
`;
export const Details = styled.div`
  display: grid;
  margin-left: 8px;
  width: 60%;
  span {
    margin-right: 10px;
    font-size: 11px;
  }
`;

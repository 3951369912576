import styled from 'styled-components';

export const Main = styled.div`
    align-self: center;
    justify-self: center;
    width: 90%;
    height: 78%;
    .nav{
        display: flex;
        justify-content: space-between;
        padding: 1%;
        height: 10%;
        svg{color: #4EAD8F;}
    }
    .pagination{
        display: flex;
        justify-content: flex-end;
        padding: 1%;
        height: 10%;
    }
    @media (min-width: 320px) and (max-width: 450px) {
        .nav{
            flex-direction: column;
            align-items: center;
            h1{margin-bottom: 12px; text-align: center;font-size: 18px;}
            div{ width: 100%;}
        }
        .pagination{
            justify-content: center;
            padding: 5%;
        }
    }
`;
export const Section = styled.div`
    width: 100%;
    height: 78%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(5, 1fr);
    overflow: auto;
    margin-top: 1%;
    ::-webkit-scrollbar {
        width: 10px;
    }
    ::-webkit-scrollbar-track {
        background: #f1f1f1; 
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }
    @media (min-width: 320px) and (max-width: 450px) {
        grid-template-columns:  1fr;
    }
`;
export const Card = styled.div`
    background-color: white;
    width: 96%;
    max-width: 250px;
    max-height: 100px;
    margin: 8px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    cursor: pointer;
    .name{
        font-weight:bold;
        color: #216632;
        padding: 8px 12px;
    }
    @media(max-width: 450px){
        min-width: 100%;
        align-items: center;
        justify-self:center;
    }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
export const HeaderCard = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #4EAD8F;
    button{
        background: transparent;
        border: none;
        color: #4EAD8F;
        :hover{color: #216632;}
    }
`;
export const Info = styled.div`
    padding: 2% 6%;
    display:flex;
    flex-direction: column;
    border-top: 1px solid green;
    color: #8C8B92;
    width: 100%;
    div{
        display: flex;
        align-items: flex-start;
        padding: 4px 0;
        p{margin-left: 6px;}
        svg{font-size: 18px;}
        h4{color: #4EAD8F; text-shadow: 0px 3px 6px #00000029;}
    }
    .infoModal{display: block;}
`;
export const InfoModal = styled.div`
    padding: 0;
    display:flex;
    flex-direction: column;
    border-top: 1px solid green;
    color: #8C8B92;
    width: 100%;
    div{
        display: block;
        padding: 4px 0;
        p{margin-left: 6px;}
        svg{font-size: 18px;}
        h4{color: #4EAD8F; text-shadow: 0px 3px 6px #00000029;}
    }
`;
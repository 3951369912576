import React from "react";

import { Card, Info, Image, Details } from "./styled";
import casa from "../../images/casa.png";
import { LongMenu } from "./Menu";
import { formatedValue } from "../../hooks/FormatedValue";

export const Cards = ({ imovel, listProperty, setListProperty }) => {
  return (
    <Card>
      <div className="barra-do-menu">
        <div>
          <div
            style={{background: imovel.status ? "#216632" : "#dc3545"}}
            className="status"
          />
        <p className="codigo">Código: {imovel.id}</p>
        </div>
          <LongMenu id={imovel.id} listProperty={listProperty} setListProperty={setListProperty}/>
      </div>
      <Info>
        <Image
          src={imovel.files.length ? imovel.files[0].url : casa}
          status={imovel.status}
        >
        {imovel.status? '':  <p>{imovel.disponibilidade === 'Alugar'? '+1 ALUGADO':'+1 VENDIDO'}</p> }
        </Image>
        <Details>
          <p className="titulo">{imovel.titulo}</p>
          <p>
            {imovel.tipo} | {imovel.estado}
          </p>
          <p>{imovel.disponibilidade}</p>
          <p className="valor">{formatedValue(imovel.valor)}</p>
          <div>
            {imovel.quartos === '00' ? "" : <span>{imovel.quartos} Quartos</span>}
            <span>{imovel.area} m²</span>
            <span>{imovel.cidade}</span>
          </div>
        </Details>
      </Info>
    </Card>
  );
};

import styled, { keyframes } from "styled-components";

const slideFromLeft = keyframes`
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #e0e8ea;
  .main {
    animation: ${slideFromLeft} 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  #buttonWhatsApp {
    left: 15px;
  }
  @media (max-width: 450px) {
    min-height: 100vh;
    height: auto;
    min-width: 100%;
    .nav {
      display: flex;
      justify-content: space-between;
      .button {
        position: relative;
        right: 0;
        button {
          background-color: #184c25;
          border: none;
        }
      }
    }
    .main {
      min-height: 100vh;
      padding-bottom: 50px;
    }
    .pagination {
      bottom: 0;
    }
    #buttonWhatsApp {
      position: fixed;
      bottom: 40px;
      padding: 0;
      height: 50px;
      min-width: 50px;
      border-radius: 50%;
      animation: pulse 0.7s infinite;
      animation-direction: alternate;
      -webkit-animation-name: pulse;
      animation-name: pulse;
      
      .textWhatsApp {
        display: none;
      }
    }

    @-webkit-keyframes pulse {
      0% {
        -webkit-transform: scale(1);
        //-webkit-filter: brightness(100%);
      }
      100% {
        -webkit-transform: scale(1.1);
        //-webkit-filter: brightness(200%);
      }
    }

    @keyframes pulse {
      0% {
        transform: scale(1);
        //filter: brightness(100%);
      }
      100% {
        transform: scale(1.1);
        //filter: brightness(200%);
      }
    }
    .footer {
      position: fixed;
      p {
        font-size: 10px;
      }
    }
  }
`;

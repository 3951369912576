import styled from "styled-components";
import { Form as Unform } from "@unform/web";

export const Main = styled.div`
  align-self: center;
  justify-self: center;
  width: 94%;
  height: 85%;
  .nav {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    svg {
      color: #4ead8f;
    }
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
  }
  @media (max-width: 450px) {
    width: 100%;
    .pagination {
      justify-content: center;
      padding: 5%;
    }
    .tagVendido {
      margin-top: 170px;
    }
  }
`;

export const FiltersContainer = styled.div`
  @media (max-width: 450px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .buttonFilter {
      border-radius: 10px;
      border: none;
      margin: 9px;
      padding-top: 4px;
      padding-bottom: 4px;
      background-color: #28a745;
      color: white;
      width: 95%;
      height: 40px;
      :hover {
        background-color: #28a745;
      }
    }
    .buttonClearFilter {
      margin: 0;
      color: #28a745;
      background-color: white;
    }
    .inputValue {
      max-width: 100%;
      margin: 0 9px;
      input {
        padding: 0 9px;
        min-width: 100%;
      }
    }
    .css-1wa3eu0-placeholder {
      padding: 0px;
    }
  }
  @media (min-width: 460px) {
    .buttonFilter,
    .buttonClose,
    .buttonClearFilter {
      display: none;
    }
  }
`;

export const BodyFilter = styled(Unform)`
  display: ${(props) => (props.open === false ? "flex" : "none")};
  z-index: 999;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 0;

  path,
  div {
    color: #4ead8f;
    cursor: pointer;
  }

  button,
  input {
    border: 1px solid #4ead8f;
    background: transparent;
    border-radius: 10px;
    height: 40px;
    max-height: 40px;
    font-size: 12px;
  }

  button {
    border-radius: 10px;
    padding: 4px 8px;
    width: 60px;
    margin-left: 2%;
    cursor: pointer;
    background-color: #4ead8f;
    path {
      color: #ffffff;
    }
    svg {
      font-size: xx-large;
    }
    :hover {
      background-color: transparent;
      box-shadow: 0px 3px 6px #00000029;
      path {
        color: #4ead8f;
      }
    }
  }
  input {
    max-width: 100%;
    padding: 6%;
    color: #4ead8f;
    ::-webkit-input-placeholder {
      color: #4ead8f;
    }
    &:focus {
      outline: 0;
      border: 1px solid #28a745;
      background-color: #ffffff;
    }
  }

  @media (max-width: 450px) {
    display: ${(props) => (props.open === false ? "none" : "flex")};
    top: 54px;
    flex-direction: column;
    position: absolute;
    background-color: #fdfdfd;
    box-shadow: 0px 3px 6px #00000029;
    padding: 37px 6px 0 6px;
    border-radius: 10px;
    width: 96%;
    gap: 6px;
    input,
    div:first-child {
      background-color: white;
      border-radius: 10px;
    }
    .buttonClose {
      background-color: transparent;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: none;
      position: absolute;
      right: 8px;
      top: -8px;
      path {
        color: green;
      }
    }
    button {
      align-self: center;
      width: 95%;
      margin: 9px 0;
      background-color: green;
      path {
        color: white;
      }
    }
  }
`;

export const SearchContainer = styled.div`
  width: 60%;
  padding: 0 13px  8px 8px;
  @media (max-width: 450px) {
    width: 100%;
    padding: 0;
  }
`;

export const Section = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    grid-template-columns: 1fr;
  }
  .titulo {
    font-size: 16px;
    color: #216632;
    font-weight: bolder;
  }
  .valor {
    color: #216632;
    font-weight: bolder;
  }
`;

export const Card = styled.div`
  background-color: white;
  min-height: 140px;
  margin: 8px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 8px 12px;
  color: #8c8b92;
  cursor: pointer;

  @media (max-width: 450px) {
    flex-direction: column;
    min-height: ${(props) => props.tamanho};
    margin: 12px;
  }
`;

export const TopCard = styled.div`
  display: flex;
  justify-content: space-between;
  .codigoEfavorito {
    display: flex;
    p {
      align-self: center;
      font-size: 10px;
      color: #216632;
      max-width: 99px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      :hover {
        text-overflow: clip;
        max-width: none;
      }
    }
  }
`;

export const ContainerImage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    display: none;
  }
  @media (max-width: 450px) {
    height: 100%;
    width: 100%;
    button {
      display: unset;
      height: 36px;
      width: 36px;
      padding: 5px;
      background: #e0e8ea;
      border: none;
      margin: 8px;
      font-weight: bolder;
      border-radius: 50%;
      color: #216632;
      text-align: center;
      position: absolute;
      cursor: pointer;
      :hover {
        background-color: #ffffff;
      }
      svg {
        height: 100%;
        width: 100%;
        text-align: center;
      }
      path {
        height: 50px;
      }
    }
    .button-rigth {
      right: 6%;
    }
  }
`;

export const Image = styled.div`
  min-width: 200px;
  min-height: 120px;
  border-radius: 10px;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  align-self: flex-start;
  .tagStatus {
    color: white;
    font-weight: bold;
    text-align: center;
    width: 100%;
    margin-top: 40%;
    background-color: #dc3545;
    padding: 5px;
    opacity: 1;
  }
  @media (max-width: 450px) {
    min-height: 220px;
    width: 100%;
    margin: 10px 0;
  }
`;

export const ImageDefault = styled.div`
  width: 100%;
  transition: all 0.4s;
  height: 60%;
  width: 100%;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
  p {
    color: white;
    font-weight: bold;
    text-align: center;
    width: 100%;
    top: 70%;
    background-color: #dc3545;
    padding: 2%;
    opacity: 1;
    position: relative;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 8px;
  width: 100%;
  height: 90%;
  .infos-row {
    display: flex;
    justify-content: space-between;
    font-size: 11px;
  }
  @media (max-width: 450px) {
    margin: 0;
  }
`;

export const Description = styled.div`
  display: none;
  @media (max-width: 450px) {
    justify-self: center;
    align-self: center;
    width: 100%;
    flex-direction: ${(props) => props.position};
    display: flex;
    margin-top: 8px;
    p {
      color: green;
    }
    button {
      display: flex;
      flex-direction: column;
      color: green;
      align-items: center;
      border: none;
      background-color: transparent;
    }
  }
`;

export const Favorite = styled.div`
  color: #4ead8f;
`;

export const ListProperty = styled.div`
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ShowProperty = styled.div`
  position: fixed;
  right: 3%;
  width: 34%;
  height: 78%;
  margin-top: 0.5%;
  max-width: 90%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 12px;
  padding: 8px 12px;
  color: #8c8b92;
  display: ${(props) => (props.displayNone === 0 ? "none" : "flex")};
  flex-direction: column;
  span {
    font-size: 12px;
    text-shadow: 0px 1px 3px #00000029;
  }
  .titulo {
    font-size: 16px;
  }
  .descricao {
    color: #216632;
    font-size: 12px;
    text-align: justify;
    max-height: 140px;
    height: auto;
    overflow: auto;
    white-space: pre-wrap;
    width: 101%;
    p {
      width: 99%;
    }
    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      margin-left: 4px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background: #3bb358;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #216632;
    }
  }
  @media (max-width: 768px) {
    display: none;
    width: 0;
  }
  .details {
    max-height: 40%;
    display: flex;
    flex-direction: column;
    padding-top: 4%;
    div {
      display: flex;
      justify-content: space-between;
      margin: 2px;
    }
  }
`;

import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { UploadImages } from "../RegisterPropertyPage/UploadImages";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
  },
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  button {
    padding: 10px;
    border-radius: 10px;
    border: none;
    margin: 12px 10px;
    color: white;
  }
  .cancelar {
    background: #dc3545;
  }
  .salvar {
    background: #216632;
  }
  button:disabled{
    background: #88888887;
  }
`;

export const ModalAddFile = ({
  modalIsOpen,
  setIsOpen,
  setUploadedFiles,
  uploadedFiles,
  createFile,
  cancelUpload
}) => {
  
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setIsOpen(false)}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <Container>
        <UploadImages
          label=""
          name="anexo"
          setUploadedFiles={setUploadedFiles}
          uploadedFiles={uploadedFiles}
        />
        <Buttons>
          <button className="cancelar" onClick={cancelUpload}>
            CANCELAR
          </button>
          <button
            className="salvar"
            onClick={createFile}
            disabled={uploadedFiles.length === 0 ? true : false}
          >
            ADICIONAR
          </button>
        </Buttons>
      </Container>
    </Modal>
  );
};

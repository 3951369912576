import React, { useRef, useEffect } from 'react';
import ReactSelect from 'react-select';
import { useField } from '@unform/core';
import { customStyles } from '../../components/CustomStylesSelect';

export const SelectEdit = ({ className, options, label, name, ...rest }) => {
    const selectRef = useRef(null);
    const { fieldName, defaultValue, registerField, error } = useField(name);
    
    useEffect(() => {
        registerField({
          name: fieldName,
          ref: selectRef.current,
          setValue: (ref, value) => { ref.select.setValue(value); },
          clearValue: (ref) => {
            ref.select.clearValue();
          },
          getValue: rest.isMulti
            ? (ref) =>
                ref.state.value?.map((option) => option.value) || []
            : (ref) => (ref.state.value ? ref.state.value.value : "")
        });
      }, [fieldName, registerField, rest.isMulti]);

    return (
        <div className={className}>
            {label && <label htmlFor={fieldName}>{label}</label>}
            <ReactSelect
                defaultValue={defaultValue && options.find((option) => option.label === defaultValue)}
                ref={selectRef}
                classNamePrefix="react-select"
                styles={customStyles}
                options={options}
                {...rest}
            />
            {error && <p style={{ color: '#F00' }}>{error}</p>}
        </div>
    );
};
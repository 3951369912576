import React, { useRef, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

import { useField } from '@unform/core';

export const Inputmask = ({ label, name, className, ...rest }) => {
    const inputRef = useRef(null);
    const { fieldName, registerField, defaultValue , error } = useField(name);
    const [maskState, setMask] = useState();
    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value'
        });
    }, [fieldName, registerField, maskState]);
    
    return (
        <div className={className}>
            {label && <label htmlFor={fieldName}>{label}</label>}
            <InputMask 
                ref={inputRef}  
                value={maskState}
                defaultValue={defaultValue} 
                {...rest}
            />
            {error && <p style={{ color: '#F00'}}>{error}</p>}
        </div>
    );
};
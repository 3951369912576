import React from "react";
import styled from "styled-components";

const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  justify-content: center;
  background-color: #184c25;
  padding: 6px;
  color: #ffffff;
  text-align: center;
  a {
    text-decoration: none;
    color: #ffffff;
    :hover {
      color: #3bb358;
    }
  }
  p {
    font-size: 12px;
  }
`;

export const Footer = () => {
  return (
    <FooterContainer className="footer">
      <p>
        Copyright © 2020 Todos os direitos reservados{" "}
        <a href="https://2tinformatica.com.br" target="blank">
          2T Informática
        </a>
      </p>
    </FooterContainer>
  );
};

import React from "react";
import styled from 'styled-components';
import { PageTitle } from "./PageTitle";

const Container = styled.div`
    min-width: 100%;
    align-self: center;
    justify-self: center;
    padding: 12px;
    grid-column: 1 / 5;
    margin-top: ${props => props.margin};
    position: ${props => props.position || "relative"};
    @media(max-width: 450px){
        position: unset;
    }
`;

export const NoProperty = (props) => {
    return (
        <Container position={props.position} margin={props.margin}>
            <PageTitle text={props.text}/>
        </Container>
    );
};
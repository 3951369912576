import React from "react";

import { Card, Info } from "./styled";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";

export const Cards = ({ contato, showContact }) => {

  return (
    <Card onClick={()=>showContact(contato)}>
      <p className="name">{contato.nomeContato}</p>
      <Info>
        <div>
          <EmailIcon />
          <p>{contato.emailContato} </p>
        </div>
        <div>
          <PhoneIcon />
          <p>{contato.telefoneContato}</p>
        </div>
      </Info>
    </Card>
  );
};

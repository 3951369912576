import React from 'react';
import { Router } from './Router';
import { GlobalStyle } from './styles';

function App() {
  return (
    <>
      <GlobalStyle />
      <Router />
    </>
  )
};

export default App;

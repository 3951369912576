import React, { useEffect, useState } from "react";
import axios from "axios";

import { Main, Section } from "./styled";
import { Container } from "../../components/Container";
import { PageTitle } from "../../components/PageTitle";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { SearchBar } from "../../components/SearchBar";
import { PaginationProgress } from "../../components/PaginationProgress";
import { Cards } from "./Cards";
import { baseUrl } from "../../Constant";
import { Buttons } from "./Buttons";
import { useProtectedPage } from "../../hooks/useProtectedPage";
import { NoProperty } from "../../components/NoProperty";

export const ListPropertyPage = () => {
  const [listProperty, setListProperty] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [textValue, setTextValue] = useState("Carregando...");

  const token = localStorage.getItem("authorization");

  useProtectedPage();

  useEffect(() => {
    const getProperty = async () => {
      const response = await axios.get(`${baseUrl}/properties`, {
        headers: { auth: token },
        params: { page, perPage: 18 },
      });
      setNumberOfPages(response.data.numberOfPages);
      setListProperty(response.data.properties);

      if (response.data.properties.length === 0) {
        setTextValue("Nenhum Imóvel foi cadastrado");
      }
    };
    getProperty();
  }, [page]);
  
  const search = async (e) => {
    e.preventDefault();
    try {
      let response;
      if (searchValue === "") {
        response = await axios.get(`${baseUrl}/properties`, {
          params: { page, perPage: 18 },
        });
      } else {
        response = await axios.get(`${baseUrl}/search`, {
          params: {
            search: searchValue,
            page,
            perPage: 6,
          },
        });
      }

      setNumberOfPages(response.data.numberOfPages);
      setPage(1);
      setListProperty(response.data.properties);

      if (response.data.properties.length === 0) {
        setTextValue(`Nenhum Imóvel foi encontrado como "${searchValue}`);
      }
    } catch (err) {
      console.log("Deu erro", err);
    }
  };
  return (
    <Container>
      <Header button={<Buttons />} />
      <Main className="main">
        <div className="nav">
          <PageTitle text="Lista de Imóveis Cadastrados" />
          <SearchBar
            text="Pesquisar por título, cidade, tipo ou código"
            search={search}
            setSearchValue={setSearchValue}
          />
        </div>
        <Section>
          {listProperty.length === 0 ? (
            <NoProperty text={textValue} />
          ) : (
            listProperty.map((property) => {
              return (
                <Cards
                  key={property.id}
                  imovel={property}
                  listProperty={listProperty}
                  setListProperty={setListProperty}
                />
              );
            })
          )}
        </Section>
        <PaginationProgress
          setPage={setPage}
          page={page}
          numberOfPages={numberOfPages}
        />
      </Main>
      <Footer />
    </Container>
  );
};

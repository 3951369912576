import styled from "styled-components";
import { Form as Unform } from "@unform/web";

export const Section = styled(Unform)`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2% 20%;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
  p {
    font-size: 12px;
  }
  button {
    align-self: flex-end;
    padding: 15px;
    width: 20%;
    font-size: 20px;
    border: none;
    border-radius: 10px;
    margin: 22px 0;
    background-color: #216632;
    color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    grid-row: span 1;
  }
  @media (max-width: 450px) {
    padding: 2% 10%;
    input,
    label,
    button {
      width: 100%;
    }
  }
`;
export const SubTitle = styled.p`
  text-align: start;
  width: 100%;
  color: #287438;
  border-bottom: 1px solid #287438;
  margin-top: 30px;
  padding-bottom: 8px;
  font-size: 15px;
`;
export const Form = styled.div`
  width: 100%;
  display: grid;
  flex-direction: column;
  align-items: flex-start;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 32px;
  .long {
    display: flex;
    flex-direction: column;
    grid-column: span 2;
  }
  .short {
    display: flex;
    flex-direction: column;
    grid-column: span 1;
  }
  .row-anexo {
    display: flex;
    flex-direction: column;
    align-self: start;
    grid-row: span 3;
  }
  label {
    text-align: start;
    margin-top: 22px;
    font-size: 15px;
    color: #287438;
  }
  input,
  textarea,
  select {
    min-height: 46px;
    font-size: 15px;
    color: #1a1a1d;
    padding: 12px;
    border: none;
    margin-top: 6px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    resize: none;
    &:focus {
      outline: 0;
      border: 1px solid #28a745;
    }
  }
  textarea {
    height: 150px;
  }
  @media (max-width: 450px) {
    display: flex;
    flex-direction: column;
    input,
    textarea,
    select {
      width: 100%;
    }

    .short, .long{
     width: 100%;
    }
  }
`;

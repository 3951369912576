import React from "react";
import ReactModal from "react-modal";
import { Container, InfoModal, HeaderCard } from "./styled";
import CloseIcon from '@material-ui/icons/Close';

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",

    borderRadius: "10px",
    transform: "translate(-50%, -50%)",
    width: "600px",
    '@media(max-width: 450px)':{
      maxWidth: "200px"
    }
  },

};


export const ModalContact = ({ modalIsOpen, setIsOpen, contato }) => {
  return (
    <ReactModal
      isOpen={modalIsOpen}
      onRequestClose={() => setIsOpen(false)}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <Container>
        <HeaderCard>
          <p>Contato do Cliente</p>
          <button onClick={() => setIsOpen(false)}><CloseIcon/></button>
        </HeaderCard>
        <InfoModal>
          <div>
            <h4>Nome: </h4>
            <p>{contato.nomeContato}</p>
          </div>
          <div>
            <h4>E-mail: </h4>
            <p>{contato.emailContato} </p>
          </div>
          <div>
            <h4>Telefone: </h4>
            <p>{contato.telefoneContato}</p>
          </div>
          <div>
            <h4>Descrição: </h4>
            <p>{contato.descricaoContato}</p>
          </div>
        </InfoModal>
      </Container>
    </ReactModal>
  );
};

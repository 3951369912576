import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import styled from "styled-components";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { casa } from "../images/casa.png";
import CloseIcon from "@material-ui/icons/Close";

const getModalStyle = () => {
  const top = 40;
  const left = 40;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};
const Paper = styled.div`
  position: absolute;
  width: 70%;
  height: 80%;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    height: 80px;
    width: 80px;
    position: relative;
    padding: 1%;
    background: #e0e8ea;
    opacity: 0.8;
    border: none;
    margin: 30px;
    border-radius: 50%;
    color: #216632;
    :focus {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: 0px;
    }
  }
  .buttonClose{
    position: absolute;
    top: -40px;
    right: -40px;
    height: 60px;
    width: 60px;
    color: #ffffff;
    background-color: transparent;
  }
  svg {
    height: 100%;
    width: 100%;
    text-align: center;
  }
  path {
    height: 50px;
  }
`;

export const ModalImageZoom = (props) => {
  const [modalStyle] = useState(getModalStyle);

  const body = (
      <Paper
        style={modalStyle}
        src={props.selectedImage === undefined ? casa : props.selectedImage.url}
      >      
      <button className="buttonClose" onClick={() => props.setOpen(false)}>
        <CloseIcon />
      </button>
        <button onClick={props.previousImage}>
          <ChevronLeftIcon />
        </button>
        <button onClick={props.nextImage}>
          <ChevronRightIcon />
        </button>
      </Paper>
  );

  return (
    <Modal
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

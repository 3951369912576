import React from "react";
import styled from "styled-components";
import { Nav } from "./Nav";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import { Link } from "react-router-dom";

const HeaderContainer = styled.div`
  position: static;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #184c25;
  color: #ffffff;
`;

const HeaderItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 94%;
  padding: 6px 0;
  .creci {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    color: #ffffff;
    p {
      margin-left: 6px;
    }
  }
  @media (max-width: 450px) {
    flex-direction: row-reverse;
    align-items: center;
    p {
      width: auto;
      font-size: 10px;
    }
    .creci {
      position: relative;
    }
    svg {
      width: 14px;
    }
  }
`;
export const Header = (props) => {
  return (
    <HeaderContainer>
      <HeaderItem>
        <a href={"mailto:imobiliariaterraviva@hotmail.com"}>
          <EmailIcon />
          <p>imobiliariaterraviva@hotmail.com</p>
        </a>
        <p className="creci">CRECI 3775 J</p>
        <a href={"tel:+5508433160856"}>
          <PhoneIcon />
          <p>(84) 3316-0856</p>
        </a>
      </HeaderItem>
      <Nav button={props.button} />
    </HeaderContainer>
  );
};

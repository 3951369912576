import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const Button = styled.button`
    border: 1px solid #FFFFFF;
    background: transparent;
    border-radius: 10px;
    padding: 12px 18px;
    color: #FFFFFF;
    cursor: pointer;
    :hover{
      background-color: #184C25;
      border: none;
    }
`;

export const ButtonContact = () => {
  const history = useHistory();
  const toGoPage = () => {
    history.push('/contato');
  };

  return <Button onClick={toGoPage} > Contato</Button>

};

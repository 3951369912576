import React from 'react';
import Modal from 'react-modal';
import styled from "styled-components";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h3{
        text-align:center;
        color: #dc3545;
    }
    button{ 
        padding: 10px; 
        border-radius: 10px; 
        border: none; 
        margin: 20px 10px; 
        color: white;
    }
    .cancelar{background: #dc3545;}
    .confirmar{background: #216632;}
`;

export const ModalDelete = ({ modalIsOpen, setIsOpen, text, deleteItem }) => {

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setIsOpen(false)}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <Container>
                <h3>{text}</h3>
                <div>
                    <button className="cancelar" onClick={() => setIsOpen(false)}>Cancelar</button>
                    <button className="confirmar" onClick={deleteItem}>Confirmar</button>
                </div>
            </Container>
        </Modal>
    );
}